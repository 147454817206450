import React from 'react';
import classes from './ExcursionReviews.module.scss';
import {priceFormatting} from "../../../helpers/PriceHelper";
import {dateParse} from "../../../helpers/DateHelper";
import HbButton from "../../UI/HbButton/HbButton";
import RatingStarsRow from "../../dashboard/common/RatingStarsRow/RatingStarsRow";
import RatingStarFilled from "../../../images/icons/RatingStarFilled.svg";


const ExcursionReviews = (props) => {
    return (
        <div className={classes.reviews_container}>
            <div className={classes.reviews_title_row}>
                <h2 className={classes.reviews_title}>
                    Что о нас думают гости:
                </h2>
            </div>

            {/*<div className={classes.reviews_count_row}>*/}
            {/*    <div className={classes.pink_dot}>*/}

            {/*    </div>*/}

            {/*    <div className={classes.reviews_count}>*/}
            {/*        отзывов ({priceFormatting(props.excursion.reviews_count)})*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div
                className={classes.reviews_row}
            >
                {
                    props.excursion.reviews.map((review, index) => {
                        return (
                            <div
                                className={`roundblock_angle_rb ${classes.review_container}`}
                                key={`review_${index}`}
                                onClick={() => props.reviewsVisible(true)}
                            >
                                <div className={`${classes.review_block}`}>
                                    <div className={classes.profile_row}>
                                        <div className={classes.user_avatar}>

                                        </div>

                                        <div className={classes.user_data}>
                                            <div className={classes.name_rating_container}>
                                                <div className={classes.user_name}>
                                                    {review.is_anonymously ? 'Скрытный авантюрист' : review.user_name}
                                                </div>

                                                <div className={classes.user_rating_container}>
                                                    <div
                                                        className={classes.rating_star_row}
                                                    >
                                                        {
                                                            [...Array(+review.rating)].map((_, index) => {
                                                                return (
                                                                    <img
                                                                        key={index}
                                                                        className={classes.rating_star_img}
                                                                        src={RatingStarFilled}
                                                                        alt={'star'}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                    <div
                                                        className={classes.rating_star_row_md}
                                                    >
                                                        <img
                                                            className={classes.rating_star_img}
                                                            src={RatingStarFilled}
                                                            alt={'star'}
                                                        />
                                                    </div>


                                                    <div className={classes.user_rating}>
                                                        ({review.rating.toFixed(1)})
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classes.review_date}>
                                                {dateParse(review.created_at)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.review_body}>
                                        {review.body}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

            <div className={classes.reviews_button_row}>
                <HbButton
                    color={'black'}
                    className={classes.reviews_button}
                    onClick={() => props.reviewsVisible(true)}
                >
                    Показать все
                </HbButton>
            </div>
        </div>
    );
};

export default ExcursionReviews;