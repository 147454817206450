import React, {useEffect, useMemo, useRef, useState} from 'react';
import classes from "./BookingCalendar.module.scss";
import moment from "moment/moment";
import HbButton from "../../UI/HbButton/HbButton";
import ArrowLeft from '../../../images/icons/ArrowLeft.svg';
import ArrowRight from '../../../images/icons/ArrowRight.svg';
import {Months} from "../../../lang/ru/Months";
import {DaysWeek} from "../../../lang/ru/DaysWeek";
import {useSearchParams} from "react-router-dom";

const BookingCalendar = ({schedule, excursionType, setDate, date, setIsCalendarVisible, getDate}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const calendarRow = useRef();
    const previousMonthButtonBlock = useRef();
    const nextMonthButtonBlock = useRef();
    const monthBlock = useRef();

    // Check search params
    useEffect(() => {
        if (setDate !== undefined) {
            if (searchParams.has('date') && schedule[excursionType.type_name].find(el => el.date === searchParams.get('date')) !== undefined) {
                setDate(moment(searchParams.get('date')));
            }else {
                setDate(moment(schedule[excursionType.type_name][0].date));
            }
        }
    }, [excursionType, searchParams])

    // Func for hidden arrows
    const calendarHiddenArrow = () => {
        if (calendarRow.current.scrollLeft === 0) {
            previousMonthButtonBlock.current.classList.add('hidden');
        } else {
            previousMonthButtonBlock.current.classList.remove('hidden');
        }

        if (calendarRow.current.scrollLeft + calendarRow.current.offsetWidth === calendarRow.current.scrollWidth) {
            nextMonthButtonBlock.current.classList.add('hidden');
        } else {
            nextMonthButtonBlock.current.classList.remove('hidden');
        }
    }

    // Funcs for scroll by arrows
    const scrollCalendarRight = () => {
        const leftScroll = calendarRow.current.scrollLeft + monthBlock.current.offsetWidth;
        calendarRow.current.scrollLeft = leftScroll;
    }

    const scrollCalendarLeft = () => {
        const leftScroll = calendarRow.current.scrollLeft - monthBlock.current.offsetWidth;
        calendarRow.current.scrollLeft = leftScroll;
    }

    useEffect(() => {
        calendarHiddenArrow();
    }, [])

    // Calendar
    const monthCount = 6;
    const ruMonths = Months;

    const calendarList = () => {
        const currentDate = moment();
        let content = []

        for (let m = 0; m < monthCount; m++) {
            let floatDate = moment().add(m, 'month');
            let month = floatDate.month();
            let year = floatDate.year();
            let daysInMonth = floatDate.daysInMonth();
            let monthStart = floatDate.startOf('month');
            let weekdayStart =  monthStart.weekday();
            let monthDays = [];

            // console.log(weekdayStart)

            // if(weekdayStart === 0) {
            //     weekdayStart = 7;
            // }

            for (let i = 1; i <= weekdayStart; i++) {
                monthDays.push(
                    <div
                        key={`empty_${i}_${m}_${year}`}
                        className={`empty_day`}
                        id={`empty_day_${i}_${m}_${year}`}
                    >
                    </div>
                )
            }


            for (let d = 1; d <= daysInMonth; d++) {
                let day = floatDate;
                let dayClasses = [classes.day];
                let day_week = day.format('dddd');

                if ((d < currentDate.date() && month === currentDate.month() && year === currentDate.year())) {
                    dayClasses.push(classes.disabled_day);
                }else if (d === currentDate.date() && month === currentDate.month() && year === currentDate.year()) {
                    dayClasses.push(classes.current_day);
                }

                if (date !== undefined) {
                    if (date.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')) {
                        dayClasses.push(classes.selected_day);
                    }
                }


                if (schedule[excursionType.type_name].find(el => el.date === day.format('YYYY-MM-DD')) === undefined) {
                    dayClasses.push(classes.disabled_day);
                }

                monthDays.push(
                    <div
                        key={`${d}_${m}_${year}`}
                        className={dayClasses.join(' ')}
                        id={`${year}_${month}_${d}`}
                        data-date={day.format('YYYY-MM-DD')}
                        data-dayweek={day_week}
                        onClick={selectDate}
                    >
                        {d}
                    </div>
                )

                day.add(1, 'day');
            }

            content.push(
                <div
                    key={`month_block_${m}_${year}`}
                    className={`${classes.month_block}`}
                    ref={monthBlock}
                >
                    <div key={`month_name_${m}_${year}`} className={classes.month_name}>
                        {ruMonths[month]} {year}
                    </div>

                    <div key={`week_days_${m}_${year}`} className={classes.week_days}>
                        <div key={`monday_${m}_${year}`}>
                            Пн
                        </div>
                        <div key={`tuesday_${m}_${year}`}>
                            Вт
                        </div>
                        <div key={`wednesday_${m}_${year}`}>
                            Ср
                        </div>
                        <div key={`thursday_${m}_${year}`}>
                            Чт
                        </div>
                        <div key={`friday_${m}_${year}`}>
                            Пт
                        </div>
                        <div  key={`saturday_${m}_${year}`}>
                            Сб
                        </div>
                        <div  key={`sunday_${m}_${year}`}>
                            Вс
                        </div>
                    </div>

                    <div key={`month_days_${m}_${year}`} className={classes.month_days}>
                        {monthDays}
                    </div>
                </div>
            )
        }

        return content;
    }

    // Sent date to excursion booking page
    const selectDate = (event) => {
        if (!event.currentTarget.classList.contains(classes.disabled_day)) {
            let selectedDates = document.getElementsByClassName(classes.selected_day);
            for (const selectedDate of selectedDates) {
                selectedDate.classList.remove(classes.selected_day);
            }
            event.currentTarget.classList.add(classes.selected_day);
            if (setDate !== undefined) {
                setDate(moment(event.currentTarget.getAttribute('data-date')));
            }

            if (setIsCalendarVisible !== undefined) {
                setIsCalendarVisible(false);
            }

            if (getDate !== undefined) {
                getDate(event.currentTarget.getAttribute('data-date'));
            }
        }
    }

    const calendar = useMemo(calendarList, [excursionType, date]);


    return (
        <div className={classes.calendar_container}>
            <div className={classes.month_button_column}>
                <div
                    ref={previousMonthButtonBlock}
                    className={classes.month_button_block}
                    onClick={scrollCalendarLeft}
                >
                    <img
                        className={classes.month_button}
                        src={ArrowLeft}
                        alt={'prev'}
                    />
                </div>
            </div>

            <div
                className={classes.calendar_row}
                ref={calendarRow}
                onScroll={calendarHiddenArrow}
                onMouseEnter={calendarHiddenArrow}
            >
                {calendar}
            </div>

            <div className={classes.month_button_column}>
                <div
                    ref={nextMonthButtonBlock}
                    className={classes.month_button_block}
                    onClick={scrollCalendarRight}
                >
                    <img
                        className={classes.month_button}
                        src={ArrowRight}
                        alt={'next'}
                    />
                </div>
            </div>
        </div>
    );
};

export default BookingCalendar;