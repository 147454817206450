import React, {useEffect} from 'react';
import FooterLayout from "../FooterLayout/FooterLayout";
import {Outlet, useLocation, useMatches, useNavigate, useSearchParams} from "react-router-dom";
import classes from "./ExcursionLayout.module.scss";
import Close from '../../../images/icons/Close.svg';
import UserDropdownMenu from "../../UI/UserDropdownMenu/UserDropdownMenu";
import LogoDesktop from '../../../images/logos/LogoDesktopNEW.svg';
import {useCookies} from "react-cookie";
import MobileNavigationBar from "../MobileNavigationBar/MobileNavigationBar";

const ExcursionLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams]= useSearchParams();
    const [cookies, setCookie, removeCookie] = useCookies();
    let matches = useMatches();

    const back = () => {
        if (location.pathname === '/e/b') {
            let searchParamPromocode = searchParams.has('promocode') ? `?promocode=${searchParams.get('promocode')}` : '';
            navigate('/e/' + searchParams.get('id') + searchParamPromocode);
        }else if(location.pathname === '/e/b/' + matches[1].params.id) {
            navigate('/');
        }else {
            if (cookies.excursion_back_route) {
                navigate(cookies.excursion_back_route);
            }else {
                navigate('/');
            }
        }
    }

    useEffect(() => {
        window.addEventListener('popstate', back);

        return () => {
            window.removeEventListener('popstate', back);
        };
    }, [location.pathname, cookies.excursion_back_route, navigate]);

    return (
        <div id={'excursion_layout'}>
            <div className={`container_fluid ${classes.header_fluid}`}>
                <div className={`container ${classes.header}`}>
                    <div
                        className={classes.close_button}
                        onClick={back}
                    >
                        <img
                            className={classes.close_img}
                            src={Close}
                            alt={'close'}
                        />
                    </div>

                    {/*<Link to={'/'}>*/}
                    <a href={'/'}>
                        <img
                            src={LogoDesktop}
                            alt={'HIDDENBURG'}
                        />
                    </a>

                    <UserDropdownMenu/>
                </div>
            </div>

            <MobileNavigationBar/>

            <Outlet/>
            <FooterLayout/>
        </div>
    );
};

export default ExcursionLayout;