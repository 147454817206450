import {httpAxios} from "./HttpAxios";


export default class UserService {
    // static async registration(firstName, phone, email, password) {
    //     try {
    //         const response = await httpAxios.post('/api/user/registration',
    //             {
    //                 first_name: firstName,
    //                 phone: phone,
    //                 email: email,
    //                 password: password
    //             }
    //         );
    //
    //         return response;
    //     } catch (e) {
    //         console.log(e);
    //         console.log(e.response.data.message)
    //     }
    // }

    static async findUserOrCreate(email) {
        const response = await httpAxios.post('/api/user/find_user_or_create',
            {
                email: email,
            }
        );

        return response;
    }

    // CSRF
    static async csrf() {
        // try {
            const response = await httpAxios.get('/sanctum/csrf-cookie');
        // } catch (e) {
        //     console.log(e);
        // }
    }

    // User login
    static async authentication(email, password) {
        const response = await httpAxios.post('/api/user/authentication',
            {
                email: email,
                password: password,
            }
        );

        return response;
    }

    //Get user
    static async getUser() {
        const response = await httpAxios.get('/api/user');

        return response;
    }

    //Get user
    static async getUserById(id) {
        try {
            const response = await httpAxios.get('/api/user/get_user_by_id', {
                params: {
                    id: id
                }
            });

            console.log(response)
            return response;
        }catch (e) {
            console.log(e);
            console.log(e.response.data.message)
        }
    }

    //Get new password
    static async sendNewPassword(email) {
        try {
            const response = await httpAxios.post('/api/user/send_new_password', {
                email: email
            });

            return response;
        }catch (e) {
            console.log(e);
            console.log(e.response.data.message)
        }
    }

    // Save user data
    static async saveUserContacts(name, phone) {
        const response = await httpAxios.post('/api/user/save_user_contacts', {
            name: name,
            phone: phone
        });

        return response;
    }

    // Save/delete excursion to favorites
    static async saveDeleteWishExcursion(excursion_id) {
        const response = await httpAxios.post('/api/user/save_delete_wish_excursion', {
            excursion_id: excursion_id
        })

        return response;
    }

    // Get user's wishlist
    static async getUserWishlist() {
        const response = await httpAxios.post('/api/user/get_wishlist');

        return response;
    }

    // Vk auth
    static async vkAuth(code) {
        const response = await  httpAxios.post("/api/user/vk_auth_result", {
            code: code
        });

        return response;
    }

    // Yandex auth
    static async yandexAuth(code) {
        // const csrf = await httpAxios.get('/sanctum/csrf-cookie');
        // console.log("csrf")
        // console.log(csrf)
        const response = await httpAxios.post(
            "/api/user/yandex_auth_result",
            {
                code: code
            },
            // {
            //     headers: {
            //         'X-CSRF-TOKEN': csrf
            //     }
            // }
        );

        return response;
    }

    // // Check user role is manager
    // static isManager() {
    //     const user = UserService.getUser();
    //     if (user && user.role === 'manager') {
    //         return true;
    //     }
    //
    //     return false;
    // }
    //
    // // Check user role is organizer
    // static isOrganizer() {
    //     const user = UserService.getUser();
    //     if (user && user.role === 'organizer') {
    //         return true;
    //     }
    //
    //     return false;
    // }
    //
    // // Check user role is client
    // static isClient() {
    //     const user = UserService.getUser();
    //     console.log(user.role);
    //     if (user && user.role === 'client') {
    //         return true;
    //     }
    //
    //     return false;
    // }
}