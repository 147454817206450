export const Types = [
    {
        type: 'GROUP',
        name: 'Групповая экскурсия',
        description: 'группы до 30 человек\nбудете вы и другие участники '
    },

    {
        type: 'MINIGROUP',
        name: 'Мини-группа',
        description: 'мини-группы до 15 человек\nбудете вы и другие участники '
    },

    {
        type: 'INDIVIDUAL',
        name: 'Индивидуальная экскурсия',
        description: 'будете только вы, ваша компания и гид'
    }
];