import React, {useEffect, useState} from 'react';
import classes from "./ExcursionPage.module.scss";
import ExcursionService from "../../API/ExcursionService";
import {createSearchParams, useNavigate, useParams, useSearchParams} from "react-router-dom";
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import ExcursionImgCarousel from "../../components/ExcursionPageComponents/ExcursionImgCarousel/ExcursionImgCarousel";
import ExcursionDescription from "../../components/ExcursionPageComponents/ExcursionDescription/ExcursionDescription";
import ExcursionBookingPreview
    from "../../components/ExcursionPageComponents/ExcursionBookingPreview/ExcursionBookingPreview";
import ExcursionReviews from "../../components/ExcursionPageComponents/ExcursionReviews/ExcursionReviews";
import HiddenReviews from "../../components/ExcursionPageComponents/HiddenReviews/HiddenReviews";
import BookingTimes from "../../components/ExcursionPageComponents/BookingTimes/BookingTimes";
import BookingCalendar from "../../components/ExcursionPageComponents/BookingCalendar/BookingCalendar";
import {useFetching} from "../../hooks/useFetching";
import {useUserWishlist} from "../../hooks/useUserWishlist";

const ExcursionPage = () => {
    const [excursion, setExcursion] = useState();
    const mainParams = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [excursionType, setExcursionType] = useState();
    const [isReviewsVisible, setIsReviewsVisible] = useState(false);
    const [schedule, setSchedule] = useState();
    const navigate = useNavigate();

    // Get excursion by id
    const [fetchExcursion, isExcursionLoading, excursionError] = useFetching(async () => {
        const response = await ExcursionService.getCurrentExcursion(mainParams.id);
        const data = response.data;
        setExcursion(data);
        setExcursionType(data.types[0]);
        // console.log('EXCURSION:')
        // console.log(data)
    })

    ///
    useEffect(() => {
        console.log('EXCURSION ')
        console.log(excursion)
    }, [excursion])

    useEffect(() => {
        console.log('EXCURSION TYPE')
        console.log(excursionType)
    }, [excursionType])

    // Get excursion schedule
    const [fetchSchedule, isScheduleLoading, scheduleError] = useFetching(async () => {
        const response = await ExcursionService.getSchedule(mainParams.id);
        const data = response.data;
        setSchedule(data);
        console.log('SCHEDULE:')
        console.log(data)
    })

    // Find excursion type
    const setTypeBySearchParams = () => {
        if (searchParams.has('type')) {
            let findType = excursion.types.find(el => el.type_name === searchParams.get('type'));
            findType !== undefined && setExcursionType(findType);
        }
    }

    useEffect(() => {
        if (!isExcursionLoading) {
            setTypeBySearchParams()
        }
    }, [searchParams, excursion])

    useEffect(() => {
        fetchExcursion();
        fetchSchedule();
    }, [])

    // Shift reviews visibility
    const shiftReviewsVisible = (isVisible) => {
        setIsReviewsVisible(isVisible);
    }

    // Func for navigate by selected date
    const [date, setDate] = useState('');

    const getDate = (date) => {
        setDate(date);
    }

    useEffect(() => {
        if (date !== '') {
            let nextSearchParams = {
                id: excursionType.excursion_id,
                type: excursionType.type_name,
                date: date
            };

            if (searchParams.has('promocode')) {
                nextSearchParams = {
                    ...nextSearchParams,
                    promocode: searchParams.get('promocode'),
                }
            }

            navigate({
                pathname: '/e/b',
                search: `?${createSearchParams(nextSearchParams)}`,
            })
        }
    }, [date])

    // Get user wishlist
    useUserWishlist();

    // Stop scrolling on page while gallery is open
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);

    useEffect(() => {
        if (isGalleryOpen) {
            document.querySelector('#excursion_layout').style.overflow = 'hidden';
            document.querySelector('#helper_container').style.overflowY = 'hidden';
            document.querySelector('#root').style.overflow = 'hidden';
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('#excursion_layout').style.overflow = '';
            document.querySelector('#helper_container').style.overflow = '';
            document.querySelector('#root').style.overflow = '';
            document.querySelector('body').style.overflow = '';
        }
    }, [isGalleryOpen])


    return (
        <div className={`container_fluid`}>
            {
                isExcursionLoading
                ?
                <PageLoader/>
                :
                <div className={`container_fluid`}>
                    <ExcursionImgCarousel
                        excursion={excursion}
                        setIsGalleryOpen={setIsGalleryOpen}
                    />

                    <div className={`container`}>
                        <h1 className={classes.excursion_title}>
                            {excursion.title}
                        </h1>

                        <div className={classes.excursion_description_booking_container}>
                            <ExcursionDescription
                                excursion={excursion}
                                excursionType={excursionType}
                            />

                            <ExcursionBookingPreview
                                excursion={excursion}
                                excursionType={excursionType}
                            />
                        </div>

                        {/* Reviews */}
                        <ExcursionReviews
                            excursion={excursion}
                            reviewsVisible={shiftReviewsVisible}
                        />
                    </div>

                    {/* Hidden reviews */}
                    <HiddenReviews
                        excursion={excursion}
                        reviewsVisible={shiftReviewsVisible}
                        isReviewsVisible={isReviewsVisible}
                    />

                    {/* Preview dates & times */}
                    <div className={'container'}>
                        {
                            isScheduleLoading
                            ?
                                <PageLoader/>
                            :
                                <div className={classes.dates_times_container}>
                                    <BookingTimes
                                        excursionType={JSON.parse(JSON.stringify(excursionType))}
                                        schedule={JSON.parse(JSON.stringify(schedule))}
                                    />

                                    <div className={classes.dates_main_container}>
                                        <div className={classes.dates_title_block}>
                                            <h2 className={classes.dates_title}>
                                                Дата:
                                            </h2>
                                        </div>

                                        <BookingCalendar
                                            excursionType={excursionType}
                                            schedule={schedule}
                                            getDate={getDate}
                                        />
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            }
        </div>
    );
};

export default ExcursionPage;