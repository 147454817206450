import React, {useEffect, useRef} from 'react';
import classes from "./CookieNotification.module.scss";
import HbButton from "../HbButton/HbButton";
import {useCookies} from "react-cookie";

const CookieNotification = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['cookie_notification']);
    const cookieContainer = useRef();

    useEffect(() => {
        if (!cookies.cookie_notification) {
            setCookie('cookie_notification', false);
        }
    }, [])

    useEffect(() => {
        if (cookies.cookie_notification === 'false') {
            cookieContainer.current.classList.remove('hidden');
        }else if (cookies.cookie_notification === 'true') {
            cookieContainer.current.classList.add('hidden');
        }

    }, [cookies])

    // Hidden cookie
    const hiddenCookie = () => {
        setCookie('cookie_notification', true);
    }

    return (
        <div
            className={['container_fluid', 'hidden', classes.cookie_container_fluid].join(' ')}
            ref={cookieContainer}
        >
            <div className={['container', classes.cookie_container].join(' ')}>
                <div className={[classes.cookie_row, 'roundblock_angle_rb', 'shadow'].join(' ')}>
                    <div className={[classes.cookie_inner_block].join(' ')}>
                        <div className={[classes.cookie_text].join(" ")}>
                            Мы используем файлы куки, потому что любим печеньки и вас 🤗 🍪
                        </div>

                        <HbButton
                            color={'pink'}
                            disabled={false}
                            onClick={hiddenCookie}
                            className={classes.success_button}
                        >
                            Хорошо!
                        </HbButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookieNotification;