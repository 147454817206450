import React from 'react';
import classes from "./ExcursionBookingCard.module.scss";
import { priceFormatting } from "../../../helpers/PriceHelper";
import Clock from "../../../images/icons/Clock.svg";
import { baseURL } from "../../../API/HttpAxios";
import { ImgStretchingFixedDiv } from "../../../helpers/ImgStretching";

const ExcursionBookingCard = ({ excursion, excursionType }) => {
    return (
        <div className={`roundblock stroke_1_000_05 ${classes.booking_card_block}`}>
            <div className={classes.booking_card_inner_container}>
                <div className={classes.excursion_info_container}>
                    <div className={classes.excursion_img_column}>
                        <div className={`roundblock ${classes.excursion_img_block} img_block_preload`}>
                            <img
                                onLoad={ImgStretchingFixedDiv}
                                className={`center_overflow_img hidden`}
                                src={baseURL + excursion.imgs[0].img_path}
                                alt={'img'}
                            />
                        </div>
                    </div>

                    <div className={classes.excursion_info_block}>
                        <div className={classes.excursion_title}>
                            {excursion.title}
                        </div>

                        <div className={classes.excursion_info}>
                            <div className={classes.reviews_city_row}>
                                <div className={classes.pink_dot}>

                                </div>

                                <div className={classes.reviews}>
                                    отзывов ({excursion.reviews_count})
                                </div>

                                <div className={classes.city_name}>
                                    {excursion.city_name}
                                </div>
                            </div>

                            <div className={classes.duration_row}>
                                <div className={classes.duration_img}>
                                    <img
                                        src={Clock}
                                        alt={'dur'}
                                    />
                                </div>

                                <div className={classes.duration}>
                                    {excursionType.duration}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.client_fears}>
                    <ul>
                        <li>Свяжемся с вами <u>сразу</u> в рабочее время (с 9:00 до 21:00, без выходных) или на следующий рабочий день, если бронируете вне графика</li>
                        <li>Не понравится - <u>вернём деньги!</u> Без чека</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ExcursionBookingCard;