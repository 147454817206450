import React, {useEffect, useState} from 'react';
import classes from "./BookingTimes.module.scss";
import {DaysWeek} from "../../../lang/ru/DaysWeek";
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import moment from "moment";

const BookingTimes = (props) => {
    const [currentSchedule, setCurrentSchedule] = useState(props.schedule[props.excursionType.type_name]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Change schedule by type
    useEffect(() => {
        setCurrentSchedule(props.schedule[props.excursionType.type_name]);
    }, [props.excursionType])

    // Func for sort times
    const sortTimes = () => {
        console.log('CURRENT_SCHEDULE:')
        console.log(currentSchedule)

        return (
            DaysWeek.map((dayWeek, index) => {
                let finalDayWeekSchedule = [];

                // Get times for 1 day of week
                let dayWeekSchedule = currentSchedule.filter(el => el.day_week === dayWeek.day);
                // console.log('SCHEDULE AFTER filter day of week ' + dayWeek.day)
                // console.log(dayWeekSchedule)

                // Set dates for every week days and delete fixed dates before now
                let filteredDayWeekSchedule = dayWeekSchedule.map((day) => {
                    if (moment(`${day.date} ${day.time}`).isAfter(moment()) && moment(`${day.date} ${day.time}`).isBefore(moment().add(1, 'week'))) {
                        return day;
                    }
                })

                finalDayWeekSchedule = filteredDayWeekSchedule.filter(el => el !== undefined);
                // console.log('SCHEDULE AFTER filter date & time ' + dayWeek.day)
                // console.log(filteredDayWeekSchedule)

                finalDayWeekSchedule.sort((a, b) => {
                    let now = moment();

                    if (moment(`${now.format('YYYY-MM-DD')} ${a.time}`).isBefore(moment(`${now.format('YYYY-MM-DD')} ${b.time}`))) {
                        return -1;
                    }else {
                        return 1;
                    }
                })

                console.log('finalDayWeekSchedule')
                console.log(finalDayWeekSchedule)

                return (
                    <div
                        className={classes.times_row}
                        key={`times_row_${index}`}
                    >
                        <div
                            className={classes.day_week}
                            // data-date={date.format('YYYY-MM-DD')}
                        >
                            {dayWeek.short_name}
                        </div>

                        <div className={classes.times_block}>
                            {
                                finalDayWeekSchedule.length !== 0
                                    ?
                                    finalDayWeekSchedule.map((day, index_day) => {
                                        return(
                                            <div
                                                className={classes.time}
                                                key={`time_${index_day}_${index}`}
                                                data-dayweek={day.day_week}
                                                data-date={day.date}
                                                data-time={day.time}
                                                onClick={bookingRedirect}
                                            >
                                                {day.time.slice(0, 5)}
                                            </div>
                                        )
                                    })
                                    :
                                    <div className={classes.no_times}>
                                        Недоступно для бронирования
                                    </div>
                            }
                        </div>
                    </div>
                )
            })
        )
    }

    // Func for redirect to booking
    const bookingRedirect = (event) => {
        const link = `/e/b/${props.excursionType.excursion_id}`;
        navigate({
            pathname: `/e/b`,
            search: `?id=${props.excursionType.excursion_id}&type=${props.excursionType.type_name}&date=${event.currentTarget.getAttribute('data-date')}&time=${encodeURIComponent(event.currentTarget.getAttribute('data-time'))}${searchParams.has('promocode') ? `&promocode=${searchParams.get('promocode')}` : ''}`,
            // search: `?${createSearchParams({
            //     id: props.excursionType.excursion_id,
            //     type: props.excursionType.type_name,
            //     date: event.currentTarget.getAttribute('data-date'),
            //     time: event.currentTarget.getAttribute('data-time')
            // })}`
        })
    }

    return (
        <div className={classes.times_main_container}>
            <div className={classes.times_title_block}>
                <h2 className={classes.times_title}>
                    Время:
                </h2>
            </div>

            <div className={classes.times_container}>
                {sortTimes()}
            </div>
        </div>
    );
};

export default BookingTimes;