import {baseURL, httpAxios} from "./HttpAxios";
import UserService from "./UserService";

export default class ExcursionService {
    static async getAllCities() {
        try {
            const response = await httpAxios.get('/api/excursions/cities');

            return response;
        } catch (e) {
            console.log(e);
        }
    }

    // Get all categories
    static async getAllCategories(language = 'ru', city = null) {
        try {
            const response = await httpAxios.get('/api/excursions/get_all_categories', {
                params: {
                    language: language,
                    city: city
                }
            });

            return response;
        } catch (e) {
            console.log(e);
        }
    }

    // Get all categories
    static async getAllTypes(language = 'ru') {
        try {
            const response = await httpAxios.get('/api/excursions/get_all_types', {
                params: {
                    language: language
                }
            });

            return response;
        } catch (e) {
            console.log(e);
        }
    }

    // Get all payment methods
    static async getAllPaymentMethods(language = 'ru') {
        try {
            const response = await httpAxios.get('/api/excursions/get_all_payment_methods', {
                params: {
                    language: language
                }
            });

            return response;
        } catch (e) {
            console.log(e);
        }
    }

    // Create new excursion
    static async createExcursion(formData) {
        const response = await httpAxios.post('/api/excursions/create_excursion', formData);

        return response;
    }

    // Update excursion
    static async updateExcursion(formData) {
        const response = await httpAxios.post('/api/excursions/update_excursion', formData);

        return response;
    }

    // Get suggested excursion count
    static async getSuggestedExcursionCount() {
        const response = await httpAxios.get('/api/excursions/get_suggested_excursion_count');

        return response;
    }

    // Get excursions with filters
    static async getExcursions({
        language = null,
        country = null,
        city = null,
        date_start = null,
        date_end = null,
        category = null,
        price_start = null,
        price_end = null,
        group = null,
        individual = null,
        minigroup = null,
        limit = null,
        page = 1
    }) {
        try {
            // возможно придется переделать с лайками и тд
            // const csrf = await httpAxios.get('/sanctum/csrf-cookie');
            const response = await httpAxios.get('/api/excursions', {
                params: {
                    language: language,
                    country: country,
                    city: city,
                    date_start: date_start,
                    date_end: date_end,
                    category: category,
                    price_start: price_start,
                    price_end: price_end,
                    group: group,
                    individual: individual,
                    minigroup: minigroup,
                    limit: limit,
                    page: page,
                }
            });
            return response;
        } catch (e) {
            console.log(e);
            console.log(e.response.data.message);
        }
    }

    // Get excursions by where clause
    // static async getExcursionsWhere(where, page = 1) {
    //     const response = await httpAxios.get('/api/excursions/get_excursions_where', {
    //         params: {
    //             where,
    //             page
    //         }
    //     });
    //
    //     return response;
    // }

    // Get excursions by status
    static async getExcursionsByStatus(statuses = [], page = 1) {
        try {

            const response = await httpAxios.get('/api/excursions/get_excursions_by_status', {
                params: {
                    statuses: statuses,
                    page: page
                },
            });
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    // Get excursion by search for employee dashboard page
    static async getExcursionBySearchForEmployee(search){
        const response = await httpAxios.get('/api/excursions/get_excursion_by_search_for_employee', {
            params: {
                search: search,
            },
        });
        return response;
    }

    // // Get active excursions
    // static async getActiveExcursions(page = 1) {
    //     try {
    //         const response = await httpAxios.get('/api/excursions/get_active_excursions', {
    //             params: {
    //                 page
    //             }
    //         });
    //         return response;
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
    //
    // // Get unpublished excursions
    // static async getUnpublishedExcursions(page = 1) {
    //     try {
    //         const response = await httpAxios.get('/api/excursions/get_unpublished_excursions', {
    //             params: {
    //                 page
    //             }
    //         });
    //         return response;
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
    //
    // // Get suggested excursions
    // static async getSuggestedExcursions(page = 1) {
    //     try {
    //         const response = await httpAxios.get('/api/excursions/get_suggested_excursions', {
    //             params: {
    //                 page
    //             }
    //         });
    //         return response;
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    // Get price limits
    static async getPriceLimits() {
        try {
            const response = await httpAxios.get('/api/excursions/price_limits');
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    //Get current excursion by id
    static async getCurrentExcursion(excursion_id, language = 'ru') {
        try {
            const response = await httpAxios.get('/api/excursions/current_excursion', {
                params: {
                    id: excursion_id,
                    language: language
                }
            });
            return response;
        } catch (e) {
            console.log(e);
            console.log(e.response.data.message)
        }
    }

    // Get some reviews
    static async getReviews(excursion_id, page) {
        try {
            const response = await httpAxios.get('/api/excursions/get_reviews', {
                params: {
                    id: excursion_id,
                    page: page
                }
            });
            return response;
        } catch (e) {
            console.log(e);
            console.log(e.response.data.message)
        }
    }

    // Get times and dates old
    static async getScheduleOld(excursion_id) {
        try {
            const response = await httpAxios.get('/api/excursions/get_dates', {
                params: {
                    id: excursion_id
                }
            });
            return response;
        } catch (e) {
            console.log(e);
            console.log(e.response.data.message)
        }
    }

    // Get times and dates
    static async getSchedule(excursion_id, months_count  = Number(6)) {
        try {
            const response = await httpAxios.get('/api/excursions/get_schedule', {
                params: {
                    id: excursion_id,
                    months_count: months_count
                }
            });
            return response;
        } catch (e) {
            console.log(e);
            console.log(e.response.data.message)
        }
    }

    // Booking
    static async excursionBooking(excursion_type_id, date, time, members, payment_method, promocode) {
        const response = await httpAxios.post('/api/excursions/booking', {
            excursion_type_id,
            date,
            time,
            members,
            payment_method,
            promocode
        });

        return response;
    }

    // Fetch booking result
    static async bookingResult(id) {
        const response = await httpAxios.get('/api/excursions/booking_result', {
            params: {
                id
            }
        });

        return response;
    }

    // Get bookings
    static async getBookings(page = 1) {
        const response = await httpAxios.get('/api/booking/get_bookings', {
            params: {
                page
            }
        });

        return response;
    }

    // // Get bookings group by excursion_type_id, date, time
    // static async getBookingsGroupByDatetime(page = 1) {
    //     const response = await httpAxios.get('/api/booking/get_bookings_group_by_datetime', {
    //         params: {
    //             page
    //         }
    //     });
    //
    //     return response;
    // }

    // Get bookings group by
    static async getBookingsGroupBy(group_by, excursion_id, page = 1) {
        const response = await httpAxios.get('/api/booking/get_bookings_group_by', {
            params: {
                group_by,
                excursion_id,
                page
            }
        });

        return response;
    }

    // Get bookings where
    static async getBookingsWhere(where, page = 1) {
        const response = await httpAxios.get('/api/booking/get_bookings_where', {
            params: {
                where,
                page
            }
        });

        return response;
    }


    // Create payment
    static async createPayment(id) {
        const response = await httpAxios.post(baseURL + '/api/excursions/create_payment', {
            booking_id: id
        });

        return response;
    }

    // Stop excursion
    static async stopExcursion(excursion_id) {
        try {
            const response = await httpAxios.post(baseURL + '/api/excursions/stop_excursion', {
                excursion_id
            });

            console.log(response)
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    // Activate excursion
    static async activateExcursion(excursion_id) {
        try {
            const response = await httpAxios.post(baseURL + '/api/excursions/activate_excursion', {
                excursion_id
            });

            console.log(response)
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    // Reject excursion
    static async rejectExcursion(excursion_id, managerComment) {
        try {
            const response = await httpAxios.post(baseURL + '/api/excursions/reject_excursion', {
                excursion_id,
                managerComment
            });

            console.log(response)
            return response;
        } catch (e) {
            console.log(e);
        }
    }

    // Get available excursion categories
    // static async getAvailableCategories(language = 'ru') {
    //     try {
    //         const response = await httpAxios.get(baseURL + '/api/excursions/activate_excursion');
    //
    //         console.log(response)
    //         return response;
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    // Edit booking
    static async editBooking(bookingData) {
        const response = httpAxios.post(baseURL + '/api/booking/edit_booking', bookingData);

        return response;
    }

    // Find or create yandex pay order
    static async findOrCreateYandexPayOrder(booking_id, redirect_url) {
        const response = httpAxios.post(baseURL + '/api/payment/yandex_pay/find_or_create_yandex_pay_order', {
            booking_id,
            redirect_url
        });

        console.log(response);


        return response;
    }
}