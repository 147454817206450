import React, {useCallback, useContext, useEffect, useState} from 'react';
import classes from './ExperienceEditArea.module.scss';
import ExperienceTypeEditArea from "../ExperienceTypeEditArea/ExperienceTypeEditArea";
import {baseURL} from "../../../../API/HttpAxios";
import {ImgStretchingFixedDiv} from "../../../../helpers/ImgStretching";
import UserService from "../../../../API/UserService";
import {useFetching} from "../../../../hooks/useFetching";
import DotCheckbox from "../DotCheckbox/DotCheckbox";
import ExperienceEditAreaManipulateBtns from "../../../../enums/ExperienceEditAreaManipulateBtns";
import ExcursionService from "../../../../API/ExcursionService";
import {useParams} from "react-router-dom";
import AuthContext from "../../../../context/AuthContext";
import Close from '../../../../images/icons/Close.svg';

const ExperienceEditArea = ({
    excursion = {},
    // excursion = {
    //     title: '',
    //     // route: [{
    //     //     waypoint: "",
    //     //     waypoint_order: "",
    //     //     excursion_type_id: 666 // TODO: get default type
    //     // }],
    //     route: [],
    //     images: [],
    //     type_ids: [
    //         {
    //             start_address: "",
    //             importants: []
    //         }
    //     ],
    //     categories: [],
    //     available_types: [],
    //     payment_methods: [],
    //     organizer: {}
    // },
    isVisible,
    // manipulateBtns = [
    //     ExperienceEditAreaManipulateBtns.PUBLISH,
    //     ExperienceEditAreaManipulateBtns.SAVE_TO_DRAFT
    // ],
    // switchExperienceContent,
    setIsExperienceInfoBlockVisible,
    setIsExperienceEditAreaVisible
}) => {
    const {...context} = useContext(AuthContext);

    const [containerClasses, setContainerClasses] = useState([classes.container]);

    // Set visibility edit container
    useEffect(() => {
        setContainerClasses(isVisible ? [classes.container, classes.container_active] : [classes.container]);
    }, [isVisible]);


    // const [excursionName, setExcursionName] = useState('');
    const [excursionStatusAlias, setExcursionStatusAlias] = useState(excursion.status ?excursion.status.alias : '');
    const [excursionName, setExcursionName] = useState(excursion.title || '');
    const [excursionPreviewDescription, setExcursionPreviewDescription] = useState(excursion.preview_description || '');
    const [excursionMainDescription, setExcursionMainDescription] = useState(excursion.main_description || '');
    const [excursionRoute, setExcursionRoute] = useState(excursion.route || []);

    // Add new waypoint
    const addWaypoint = () => {
        setExcursionRoute([...excursionRoute, {
            waypoint: "",
            waypoint_order: "",
            // excursion_type_id: excursion.type_ids[0].id
        }]);
    }

    // Delete waypoint
    const deleteWaypoint = (e) => {
        setExcursionRoute(excursionRoute.filter((point, index) => {
            return index !== Number(e.target.dataset.waypointIndex);
        }))
    }

    // Change waypoint
    const changeWaypoint = (e) => {
        setExcursionRoute(excursionRoute.map((point, index) => {
            if (index === Number(e.target.getAttribute('data-waypoint-index'))) {
                return {
                    ...point,
                    waypoint: e.target.value
                }
            }else {
                return point;
            }
        }))
    }

    // Change waypoint order
    const changeWaypointOrder = (e) => {
        setExcursionRoute(excursionRoute.map((point, index) => {
            e.target.value = +e.target.value.replace(/\D/g, '');
            if (index === Number(e.target.getAttribute('data-waypoint-index'))) {
                return {
                    ...point,
                    waypoint_order: e.target.value
                }
            }else {
                return point;
            }
        }))
    }

    // Render route
    const renderRoute = excursionRoute.map((point, index) => {
        return (
            <div
                className={classes.experience_point_row}
                key={index}
            >
                <input
                    className={[classes.experience_point_input, "temp_input"].join(" ")}
                    placeholder="Локация"
                    value={point.waypoint}
                    onChange={changeWaypoint}
                    data-waypoint-index={index}
                />

                {/*<input*/}
                {/*    className={[classes.experience_point_order_input, "temp_input"].join(" ")}*/}
                {/*    type={"number"}*/}
                {/*    value={point.waypoint_order}*/}
                {/*    data-waypoint-index={index}*/}
                {/*    onChange={changeWaypointOrder}*/}
                {/*/>*/}

                <div
                    className={[classes.experience_point_delete_btn, "temp_btn", "temp_btn_delete"].join(" ")}
                    data-waypoint-index={index}
                    onClick={deleteWaypoint}
                >
                    <img
                        src={Close}
                        alt="x"
                        width={10}
                    />
                </div>
            </div>
        );
    })


    //// Edit photo
    const [excursionImgs, setExcursionImgs] = useState(excursion.imgs || []);

    // Change photo order
    const changePhotoOrder = (e) => {
        e.target.value = +e.target.value.replace(/\D/g, '');

        setExcursionImgs(excursionImgs.map((photo, index) => {
            if (index === +e.target.getAttribute('data-photo-index')) {
                return {
                    ...photo,
                    img_order: +e.target.value
                }
            }else {
                return photo
            }
        }))
    }

    // Delete photo
    const deletePhoto = (indexInList) => {
    // const deletePhoto = (e) => {
        setExcursionImgs(excursionImgs.filter((photo, index) => {
            return index != indexInList;
            // return index !== +e.target.getAttribute('data-photo-index');
        }))
    }

    // not working
    // const deletePhoto = useCallback((e) => {
    //     setExcursionImgs(excursionImgs.filter((photo, index) => {
    //         return index !== +e.target.getAttribute('data-photo-index');
    //     }))
    // }, [excursionImgs])

    // Add new photos
    const handleClickAddPhotos = () => {
        document.getElementById(`input_photos_${excursion.id}`).click();
    }

    const addPhotos = (e) => {
        const files = e.target.files;
        setExcursionImgs([...excursionImgs, ...Array.from(files).map((file, index) => {
            return {
                file: file,
                img_path: URL.createObjectURL(file),
                img_order: excursionImgs.length + index + 1,
                from_user: true
            }
        })])
        e.target.value = null;
    }

    // Render photos
    let photoBlocks = excursionImgs.map((photo, index) => {
        return (
            <div
                className={classes.experience_photo_block}
                key={index}
            >
                <div className={classes.experience_photo}>
                    <img
                        src={photo.from_user ? photo.img_path : baseURL + photo.img_path}
                        onLoad={ImgStretchingFixedDiv}
                    />
                </div>

                <div className={classes.experience_photo_control_block}>
                    {/*<input*/}
                    {/*    className={[classes.experience_photo_order_input, "temp_input"].join(" ")}*/}
                    {/*    type={"text"}*/}
                    {/*    value={photo.img_order}*/}
                    {/*    onChange={changePhotoOrder}*/}
                    {/*    data-photo-index={index}*/}
                    {/*    // max={99}*/}
                    {/*/>*/}

                    <div
                        className={[classes.experience_point_delete_btn, "temp_btn", "temp_btn_delete"].join(" ")}
                        data-photo-index={index}
                        onClick={() => deletePhoto(index)}
                    >
                        <img
                            src={Close}
                            alt="x"
                            width={10}
                        />
                    </div>
                </div>
            </div>
        );
    })

    useEffect(() => {
        console.log('excursionImgs')
        console.log(excursionImgs)
    }, [excursionImgs])


    ////// Edit excursion start address
    const [excursionStartAddress, setExcursionStartAddress] = useState(excursion.type_ids && excursion.type_ids[0] ? excursion.type_ids[0].start_address : '');

    // Change excursion start address
    const changeExcursionStartAddress = (e) => {
        setExcursionStartAddress(e.target.value);
    }

    ////// Edit excursion start links
    const [excursionYandexMapLink, setExcursionYandexMapLink] = useState(excursion.type_ids && excursion.type_ids[0] && excursion.type_ids[0].start_address_links && excursion.type_ids[0].start_address_links.find(el => el.map_type === 'yandex_map') !== undefined ? excursion.type_ids[0].start_address_links.find(el => el.map_type === 'yandex_map').link : '');

    const [excursionYandexNavigatorLink, setExcursionYandexNavigatorLink] = useState(excursion.type_ids && excursion.type_ids[0] && excursion.type_ids[0].start_address_links && excursion.type_ids[0].start_address_links.find(el => el.map_type === 'yandex_navigator') !== undefined ? excursion.type_ids[0].start_address_links.find(el => el.map_type === 'yandex_navigator').link : '');

    const changeYandexMapLink = (e) => {
        setExcursionYandexMapLink(e.target.value)
    }

    const changeYandexNavigatorLink = (e) => {
        setExcursionYandexNavigatorLink(e.target.value)
    }

    ////// Edit excursion importants
    const [excursionImportants, setExcursionImportants] = useState(excursion.type_ids && excursion.type_ids[0] ? excursion.type_ids[0].importants : []);

    // Change excursion importants
    const changeExcursionImportants = (e) => {
        setExcursionImportants(excursionImportants.map((important, index) => {
            if (index === +e.target.getAttribute('data-important-index')) {
                return {
                    ...important,
                    body: e.target.value
                }
            }else {
                return important
            }
        }));
    }

    // Add new excursion important
    const addExcursionImportant = () => {
        setExcursionImportants([...excursionImportants, {
            body: "",
            // excursion_type_id: excursion.type_ids[0].id
        }])
    }

    // Delete excursion important
    const deleteExcursionImportant = (e) => {
        setExcursionImportants(excursionImportants.filter((important, index) => {
            return index !== +e.target.getAttribute('data-important-index');
        }))
    }

    // Render excursion importants
    const renderExcursionImportants = excursionImportants.map((important, index) => {
        return (
            <div
                className={classes.experience_important_row}
                key={index}
            >
                <input
                    className={["temp_input", classes.experience_important_input].join(" ")}
                    value={important.body}
                    data-important-index={index}
                    onChange={changeExcursionImportants}
                />

                <div
                    className={[classes.experience_important_delete_btn, "temp_btn", "temp_btn_delete"].join(" ")}
                    onClick={deleteExcursionImportant}
                    data-important-index={index}
                >
                    <img
                        src={Close}
                        alt="x"
                        width={10}
                    />
                </div>
            </div>
        )
    })

    ////// Edit after booking important
    const [excursionAfterBookingImportant, setExcursionAfterBookingImportant] = useState(excursion.type_ids && excursion.type_ids[0] ? excursion.type_ids[0].after_booking_important : '');

    // Change after booking important
    const changeExcursionAfterBookingImportant = (e) => {
        setExcursionAfterBookingImportant({
            // type_id: excursion.type_ids[0].id,
            body: e.target.value
        })
    }

    ////// Edit experience categories
    const [availableExcursionCategories, setAvailableExcursionCategories] = useState([]);

    const [fetchingAvailableExcursionCategories, isAvailableExcursionCategoriesLoading, availableExcursionCategoriesError] = useFetching(async () => {
        const response = await ExcursionService.getAllCategories();
        setAvailableExcursionCategories(response.data.filter(el => el.alias !== 'all'));
        // console.log(response.data)
    })

    useEffect(() => {
        fetchingAvailableExcursionCategories();
    }, [])

    const [excursionCategories, setExcursionCategories] = useState(excursion.categories ? excursion.categories.map(el => el.alias) : []);

    ////// Edit excursion duration
    const [excursionDuration, setExcursionDuration] = useState(excursion.type_ids && excursion.type_ids[0] ? excursion.type_ids[0].duration : '');

    // Change excursion duration
    const changeExcursionDuration = (e) => {
        setExcursionDuration(e.target.value)
    }

    ////// Render Experience type and schedule edit area
    const [availableExcursionTypes, setAvailableExcursionTypes] = useState([]);

    const [fetchingAvailableExcursionTypes, isAvailableExcursionTypesLoading, availableExcursionTypesError] = useFetching(async () => {
        const response = await ExcursionService.getAllTypes();
        setAvailableExcursionTypes(response.data);
        // console.log(response.data)
    })

    useEffect(() => {
        fetchingAvailableExcursionTypes();
    }, [])

    const [excursionTypesData, setExcursionTypesData] = useState([]);

    useEffect(() => {
        console.log('excursionTypesData:')
        console.log(excursionTypesData)
    }, [excursionTypesData])
    // const [excursionTypesData, setExcursionTypesData] = useState(excursion.categories.map(el => el.alias) || []);


    useEffect(() => {
        if (availableExcursionTypes.length > 0 && excursion.type_ids && excursion.type_ids.length > 0) {
            // console.log(availableExcursionTypes)

            setExcursionTypesData(availableExcursionTypes.map(availableType => {
                let excursion_type = excursion.type_ids.find(el => el.alias === availableType.name);
                let excursion_schedule_type = {
                    type_alias: 'null',
                    dates: [],
                    max_member_count: 10,
                    min_hours_before_booking: 2
                };

                if (excursion_type !== undefined) {
                    if (excursion_type.dates.length > 0) {
                        excursion_schedule_type = {
                            type_alias: 'permanent_schedule',
                            dates: excursion_type.dates,
                            max_member_count: excursion_type.persons_count,
                            min_hours_before_booking: excursion_type.min_hours_before_booking
                        }
                    }else if (excursion_type.flexible_dates.length > 0) {
                        excursion_schedule_type = {
                            type_alias: 'flexible_schedule',
                            dates: excursion_type.flexible_dates,
                            max_member_count: excursion_type.persons_count,
                            min_hours_before_booking: excursion_type.min_hours_before_booking
                        }
                    }
                }

                return (
                    {
                        excursion_type_alias: availableType.name,
                        active: excursion_type ? excursion_type.active : 0,
                        members: excursion_type ? excursion_type.members : [],
                        schedule_type: excursion_schedule_type
                    }
                )
            }));
        }else {
            setExcursionTypesData(availableExcursionTypes.map(availableType => {
                // let excursion_type = excursion.type_ids.find(el => el.alias === availableType.name);
                let excursion_schedule_type = {
                    type_alias: 'null',
                    dates: [],
                    max_member_count: 10,
                    min_hours_before_booking: 2
                };

                return (
                    {
                        excursion_type_alias: availableType.name,
                        active: 0,
                        members: [],
                        schedule_type: excursion_schedule_type
                    }
                )
            }))
        }
    }, [availableExcursionTypes])
    // }, [availableExcursionTypes, excursion])
    //
    const renderExperienceTypeEditArea = availableExcursionTypes.map((type, index) => {
        return (
            <ExperienceTypeEditArea
                currentAvailableType={type}
                excursionTypesData={excursionTypesData}
                setExcursionTypesData={setExcursionTypesData}
                key={index}
            />
        )
    })

    ////// Edit organizer
    const [organizer, setOrganizer] = useState(excursion.organizer);
    const [organizerId, setOrganizerId] = useState(excursion.organizer_id || 0);

    const [userFetching, isUserLoading, userError] = useFetching(async () => {
        const response = await UserService.getUserById(organizerId);

        let data = response.data;

        if (data !== "") {
            setOrganizer(response.data);

        }
    })

    const changeOrganizerId = (e) => {
        e.target.value = e.target.value.replace(/\D+/g, '');
        setOrganizerId(e.target.value);
    }

    // Get user info
    useEffect(() => {
        if (organizerId !== '' && organizerId != 0) {
            userFetching();
        }
    }, [organizerId])

    ////// Select payment types
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);

    const [fetchingAvailablePaymentMethods, isAvailablePaymentMethodsLoadinig, availablePaymentMethodsError] = useFetching(async () => {
        const response = await ExcursionService.getAllPaymentMethods();

        setAvailablePaymentMethods(response.data.map(el => {
            return {
                name: el.info.name,
                alias: el.alias,
                description: el.info.description
            }
        }));
    })

    useEffect(() => {
        fetchingAvailablePaymentMethods();
    }, [])

    const [paymentMethods, setPaymentMethods] = useState(excursion.payment_methods ? excursion.payment_methods.map(el => el.alias) : []);

    // Excursion manager comment
    const [excursionManagerComment, setExcursionManagerComment] = useState(excursion.manager_comment ? excursion.manager_comment.body : "");

    const changeExcursionManagerComment = (e) => {
        setExcursionManagerComment(e.target.value);
    }

    ////// Send to server: title = excursionName = '', +
    // preview_description = excursionPreviewDescription = '', +
    // main_description = excursionMainDescription = '', +
    // city?=default SPB,
    // route with name and order = excursionRoute = [{waypoint, waypoint_order}], +
    // photos with order = excursionImgs = [{img_path, img_order}], +
    // meeting_place = excursionStartAddress = '', +
    // importants = excursionImportants = ['body'], +
    // important_info_after_booking = excursionAfterBookingImportant = '', +
    // categories = excursionCategories = ['alias'], +
    // duration = excursionDuration = '', +
    // organizer_id = organizerId = int, +
    // payment_methods = paymentMethods = ['alias'], +
    // manager_comment = excursionManagerComment = '', +
    // types with active, members, prices, schedule type, schedule, max member count, min time before booking
    // let test =
    // [
    //     {
    //         excursion_type_alias: '',
    //         is_active: false,
    //         members: [
    //             // {
    //             //     member: '',
    //             //     price: 100,
    //             // }
    //         ],
    //         schedule_type: {
    //             type_alias: '',
    //             dates: [
    //                 // {
    //                 //     weekday: '',
    //                 //     time: ''
    //                 // }
    //             ],
    //             max_member_count: 10,
    //             min_hours_before_booking: 1
    //         }
    //     }
    // ]

    useEffect(() => {
        // console.log(excursionName)
        // console.log(excursionPreviewDescription)
        // console.log(excursionMainDescription)
        // console.log(excursionRoute)
        // console.log(excursionImgs)
        // console.log(excursionStartAddress)
        // console.log(excursionImportants)
        // console.log(excursionAfterBookingImportant)
        // console.log(excursionCategories)
        // console.log(excursionDuration)
        // console.log(organizerId)
        // console.log(paymentMethods)
        // console.log(excursionManagerComment)
        // console.log(excursionTypesData)

    }, [excursionName])



    // Create new excursion
    const [fetchingCreateExcursion, isCreateExcursionLoading, createExcursionError] = useFetching(async () => {
        const formData = new FormData();
        excursionImgs.forEach((img, index) => {
            // let dateNow = Date.now();
            // imgData.append(`${img.img_order}_${index}_${Math.random() * 1000000}_${dateNow}`, img.file);
            formData.append('files[]', img.file);
        })

        formData.append('excursionData', JSON.stringify({
            excursionName: excursionName,
            excursionPreviewDescription: excursionPreviewDescription,
            excursionMainDescription: excursionMainDescription,
            city: 'spb',
            excursionRoute: excursionRoute,
            excursionStartAddress: excursionStartAddress,
            excursionImportants: excursionImportants,
            excursionAfterBookingImportant: excursionAfterBookingImportant,
            excursionCategories: excursionCategories,
            excursionDuration: excursionDuration,
            organizerId: organizerId,
            paymentMethods: paymentMethods,
            excursionManagerComment: excursionManagerComment,
            excursionTypesData: excursionTypesData,
            yandexMapLink: excursionYandexMapLink,
            yandexNavigatorLink: excursionYandexNavigatorLink
        }))

        const response = await ExcursionService.createExcursion(formData)

        if (response.status === 201) {
            window.location.reload();
        }
        console.log(response)
    })

    useEffect(() => {
        console.log(createExcursionError)
    }, [createExcursionError])

    // Switch excursion status by page
    const {page} = useParams();

    const [excursionStatus, setExcursionStatus] = useState(excursion.status ? excursion.status.alias : "active");

    useEffect(() => {
        if (page === 'suggested') {
            setExcursionStatus('active');
        }
    }, [page])

    // Update excursion
    const [fetchingUpdateExcursion, isUpdateExcursionLoading, updateExcursionError] = useFetching(async () => {
        const formData = new FormData();
        excursionImgs.forEach((img, index) => {
            if (img.hasOwnProperty('from_user') && img.from_user === true) {
                formData.append('files[]', img.file);
            }

            if (!img.hasOwnProperty('from_user')) {
                formData.append('imgsFromDb[]', img.id);
                // console.log("krya")
            }
        })

        // if (formData.get('files[]') === null) {
        //     formData.append('files[]', '');
        // }


        formData.append('excursionData', JSON.stringify({
            excursionStatus: excursionStatus,
            excursionId: excursion.id,
            excursionName: excursionName,
            excursionPreviewDescription: excursionPreviewDescription,
            excursionMainDescription: excursionMainDescription,
            city: 'spb',
            excursionRoute: excursionRoute,
            excursionStartAddress: excursionStartAddress,
            excursionImportants: excursionImportants,
            excursionAfterBookingImportant: excursionAfterBookingImportant,
            excursionCategories: excursionCategories,
            excursionDuration: excursionDuration,
            organizerId: organizerId,
            paymentMethods: paymentMethods,
            excursionManagerComment: excursionManagerComment,
            excursionTypesData: excursionTypesData,
            yandexMapLink: excursionYandexMapLink,
            yandexNavigatorLink: excursionYandexNavigatorLink
        }))

        const response = await ExcursionService.updateExcursion(formData)

        if (response.status === 200) {
            window.location.reload();
        }
        console.log(response)
    })

    // Set manipulate buttons
    const [manipulateBtns, setManipulateBtns] = useState([]);

    // const switchContent = () => {
    //     switchExperienceContent();
    // }

    const hideExperienceEditArea = () => {
        setIsExperienceInfoBlockVisible(true);
        setIsExperienceEditAreaVisible(false);
    }

    // Fetch reject excursion
    const [fetchingRejectExcursion, isRejectExcursionLoading, rejectExcursionError] = useFetching(async () => {
        const response = await ExcursionService.rejectExcursion(excursion.id, excursionManagerComment);

        if (response.status === 200) {
            window.location.reload();
        }
        console.log(response)
    })

    useEffect(() => {
        if (context.user.role === 'manager') {
            if (excursionStatusAlias === 'active') {
                setManipulateBtns([
                    {
                        ...ExperienceEditAreaManipulateBtns.SAVE,
                        onClick: fetchingUpdateExcursion
                    },

                    {
                        ...ExperienceEditAreaManipulateBtns.CANCEL_CHANGES,
                        onClick: hideExperienceEditArea,
                    }
                ]);
            }else if (['draft', 'rejected', 'stopped'].includes(excursionStatusAlias)) {
                setManipulateBtns([
                    {
                        ...ExperienceEditAreaManipulateBtns.SAVE,
                        onClick: fetchingUpdateExcursion
                    },

                    {
                        ...ExperienceEditAreaManipulateBtns.CANCEL_CHANGES,
                        onClick: hideExperienceEditArea
                    }
                ]);
            }else if (excursionStatusAlias === 'moderation') {
                setManipulateBtns([
                    {
                        ...ExperienceEditAreaManipulateBtns.PUBLISH,
                        // Save excursion with update
                        onClick: fetchingUpdateExcursion
                    },

                    {
                        ...ExperienceEditAreaManipulateBtns.REJECT,
                        // Set status rejected
                        onClick: fetchingRejectExcursion
                    },

                    {
                        ...ExperienceEditAreaManipulateBtns.CANCEL_CHANGES,
                        onClick: hideExperienceEditArea,
                    }
                ])
            }else {
                setManipulateBtns([
                    {
                        ...ExperienceEditAreaManipulateBtns.PUBLISH,
                        // Save new excursion
                        onClick: fetchingCreateExcursion
                    },
                ])
            }
        }else if (context.user.role === 'organizer') {
            if (excursionStatusAlias === 'active') {
                setManipulateBtns([
                    {
                        ...ExperienceEditAreaManipulateBtns.SAVE,
                        onClick: fetchingUpdateExcursion
                    },

                    {
                        ...ExperienceEditAreaManipulateBtns.CANCEL_CHANGES,
                        onClick: hideExperienceEditArea,
                    }
                ]);
            }else if (['draft', 'rejected', 'stopped'].includes(excursionStatusAlias)) {
                setManipulateBtns([
                    {
                        ...ExperienceEditAreaManipulateBtns.SAVE,
                        onClick: fetchingUpdateExcursion
                    },

                    {
                        ...ExperienceEditAreaManipulateBtns.CANCEL_CHANGES,
                        onClick: hideExperienceEditArea
                    }
                ]);
            }else if (excursionStatusAlias === 'moderation') {
                setManipulateBtns([
                    {
                        ...ExperienceEditAreaManipulateBtns.PUBLISH,
                        // Save excursion with update
                        onClick: fetchingUpdateExcursion
                    },

                    {
                        ...ExperienceEditAreaManipulateBtns.CANCEL_CHANGES,
                        onClick: hideExperienceEditArea,
                    }
                ])
            }else {
                setManipulateBtns([
                    {
                        ...ExperienceEditAreaManipulateBtns.PUBLISH,
                        // Save new excursion
                        onClick: fetchingCreateExcursion
                    },
                ])
            }
        }
    }, [excursionStatusAlias, excursionName, excursionPreviewDescription, excursionMainDescription, excursionRoute, excursionImgs, excursionStartAddress, excursionImportants, excursionAfterBookingImportant, excursionCategories, excursionDuration, organizerId, paymentMethods, excursionManagerComment, excursionTypesData, excursionStatus, excursionYandexMapLink, excursionYandexNavigatorLink])

    const manipulateBtnsBlocks = manipulateBtns.map((btn, index) => {

        return (
            <div
                className={[classes.manipulate_btn, "temp_btn", btn.classNames && btn.classNames.join(" ")].join(" ")}
                key={index}
                data-action={btn.action}
                onClick={btn.onClick}
            >
                {btn.title}
            </div>
        );
    })

    return (
        <div className={containerClasses.join(" ")}>
            {/* Experience title */}
            <div className={classes.experience_title_block}>
                <div className={classes.experience_title_label}>
                    Название экскурсии
                </div>

                <input
                    className={[classes.experience_title_input, "temp_input"].join(" ")}
                    type={"text"}
                    value={excursionName}
                    onChange={e => setExcursionName(e.target.value)}
                >

                </input>
            </div>

            {/* Experience meta description */}
            <div className={classes.experience_preview_description_block}>
                <div className={classes.experience_preview_description_label}>
                    Короткое описание
                </div>

                <textarea
                    className={[classes.experience_preview_description_textarea, "temp_input"].join(" ")}
                    rows={7}
                    value={excursionPreviewDescription}
                    onChange={e => setExcursionPreviewDescription(e.target.value)}
                >

                </textarea>
            </div>

            {/* Experience main description */}
            <div className={classes.experience_main_description_block}>
                <div className={classes.experience_main_description_label}>
                    Композиция экскурсии
                </div>

                <textarea
                    className={[classes.experience_main_description_textarea, "temp_input"].join(" ")}
                    rows={15}
                    value={excursionMainDescription}
                    onChange={e => setExcursionMainDescription(e.target.value)}
                >

                </textarea>
            </div>

            {/* Experience city */}
            <div className={classes.experience_city_block}>
                <div className={classes.experience_city_label}>
                    Город
                </div>

                <select className={[classes.experience_city_select, "temp_input"].join(" ")}>
                    <option>Санкт-Петербург</option>
                    {/*<option>Москва</option>*/}
                    {/*<option>Казань</option>*/}
                </select>
            </div>


            {/* Experience route */}
            <div className={classes.experience_route_container}>
                <div className={classes.experience_route_label}>
                    Маршрут
                </div>

                <div className={classes.experience_points_block}>
                    {renderRoute}
                </div>

                <div 
                    className={[classes.experience_add_point_btn, "temp_btn"].join(" ")}
                    onClick={addWaypoint}
                >
                    +
                </div>
            </div>

            {/* Edit photos block */}
            <div className={classes.experience_photos_container}>
                <div className={classes.experience_photos_label}>
                    Фотографии
                </div>

                <div className={classes.experience_photos_block}>
                    {photoBlocks}

                    <div className={classes.experience_photo_add_btn_block}>
                        <div
                            className={[classes.experience_photo_add_btn, "temp_btn"].join(" ")}
                            onClick={handleClickAddPhotos}
                        >
                            +
                        </div>
                    </div>
                </div>

                <input
                    // data-img_input-id={'img'}
                    className={"hidden"}
                    id={`input_photos_${excursion.id}`}
                    onChange={addPhotos}
                    type={"file"}
                    accept="image/*"
                    multiple
                />
            </div>

            {/* Meeting place */}
            <div className={classes.meeting_place_container}>
                <div className={classes.meeting_place_label}>
                    Место встречи
                </div>

                <input
                    className={["temp_input", classes.meeting_place_input].join(" ")}
                    type={"text"}
                    value={excursionStartAddress}
                    onChange={changeExcursionStartAddress}
                />
            </div>

            {/* Yandex map link */}
            <div className={classes.yandex_map_link_container}>
                <div className={classes.yandex_map_link_label}>
                    Ссылка на место встречи в Яндекс картах
                </div>

                <input
                    className={["temp_input", classes.yandex_map_link_input].join(" ")}
                    type={"text"}
                    value={excursionYandexMapLink}
                    onChange={changeYandexMapLink}
                />
            </div>

            {/* Yandex navigator link */}
            <div className={classes.yandex_navigator_link_container}>
                <div className={classes.yandex_navigator_link_label}>
                    Ссылка на место встречи в Яндекс навигаторе
                </div>

                <input
                    className={["temp_input", classes.yandex_navigator_link_input].join(" ")}
                    type={"text"}
                    value={excursionYandexNavigatorLink}
                    onChange={changeYandexNavigatorLink}
                />
            </div>

            {/* Experience importants */}
            <div className={classes.experience_importants_container}>
                <div className={classes.experience_importants_label}>
                    Важная информация
                </div>

                <div className={classes.experience_importants_block}>
                    {renderExcursionImportants}

                    <div
                        className={[classes.experience_important_add_btn, "temp_btn"].join(" ")}
                        onClick={addExcursionImportant}
                    >
                        +
                    </div>
                </div>
            </div>

            {/* Info after booking */}
            <div className={classes.experience_info_after_booking_block}>
                <div className={classes.experience_info_after_booking_label}>
                    Важная информация для гостя после бронирования
                </div>

                <textarea
                    className={[classes.experience_info_after_booking_textarea, "temp_input"].join(" ")}
                    rows={15}
                    value={excursionAfterBookingImportant ? excursionAfterBookingImportant.body : ""}
                    onChange={changeExcursionAfterBookingImportant}
                >

                </textarea>
            </div>

            {/* Experience categories */}
            <div className={classes.experience_categories_container}>
                <div className={classes.experience_categories_label}>
                    Категории
                </div>

                <div className={classes.experience_categories_block}>
                    <DotCheckbox
                        availableCheckboxes={availableExcursionCategories}
                        defaultChecked={excursionCategories}
                        setChecked={setExcursionCategories}
                    />
                </div>
            </div>

            {/* Duration */}
            <div className={classes.experience_duration_container}>
                <div className={classes.experience_duration_label}>
                    Длительность впечатления
                </div>

                <div className={classes.experience_duration_block}>
                    <input
                        className={["temp_input", classes.experience_duration_input].join(" ")}
                        type={"text"}
                        value={excursionDuration}
                        onChange={changeExcursionDuration}
                    />

                    {/*<div>*/}
                    {/*    часов*/}
                    {/*</div>*/}
                </div>
            </div>

            {/* Experience types */}
            <div className={classes.experience_types_container}>
                <div className={classes.experience_types_label}>
                    Форматы экскурсии
                </div>

                {renderExperienceTypeEditArea}
            </div>

            {/* Edit organizer */}
            {
                context.user.role === "manager" &&
                <div className={classes.edit_organizer_container}>
                    <div className={classes.edit_organizer_row}>
                        <div className={classes.edit_organizer_label}>
                            Введите ID организатора впечатления
                        </div>

                        <input
                            className={["temp_input", classes.edit_organizer_input].join(" ")}
                            type={"text"}
                            value={organizerId}
                            onChange={changeOrganizerId}
                        />

                        {/*<div className={[classes.edit_organizer_save_btn, "temp_btn"].join(" ")}>*/}
                        {/*    Сохранить*/}
                        {/*</div>*/}
                    </div>

                    <div className={classes.organizer_info_row}>
                        <div className={classes.organizer_avatar_block}>
                            <img
                                className={'center_img'}
                                src={organizer && baseURL + organizer.avatar_path}
                                onLoad={ImgStretchingFixedDiv}
                                alt={''}
                            />
                        </div>

                        <div className={classes.organizer_name_block}>
                            {organizer && organizer.name}
                        </div>
                    </div>
                </div>
            }


            {/* Payment method */}
            <div className={classes.payment_method_container}>
                <div className={classes.payment_method_label}>
                    Способ оплаты
                </div>

                <DotCheckbox
                    availableCheckboxes={availablePaymentMethods}
                    defaultChecked={paymentMethods}
                    setChecked={setPaymentMethods}
                />
            </div>

            {/* Manager comment */}
            {
                context.user.role === "manager" &&
                <div className={classes.manager_comment_container}>
                    <div className={classes.manager_comment_label}>
                        Комментарий менеджера
                    </div>

                    <textarea
                        className={[classes.manager_comment_textarea, "temp_input"].join(" ")}
                        rows={10}
                        value={excursionManagerComment}
                        onChange={changeExcursionManagerComment}
                    ></textarea>
                </div>
            }

            {
                context.user.role === "organizer" && excursion.manager_comment !== null && page !== 'create_new' &&
                <div className={classes.manager_comment_container}>
                    <div className={classes.manager_comment_label}>
                        Комментарий менеджера
                    </div>

                    <div>
                        {excursion.manager_comment.body}
                    </div>
                </div>
            }


            <div style={{color: "red"}}>{createExcursionError}</div>
            <div style={{color: "red"}}>{updateExcursionError}</div>

            {/* Manipulate buttons */}
            <div className={classes.manipulate_btns_container}>
                {manipulateBtnsBlocks}
            </div>
        </div>
    );
};

export default ExperienceEditArea;