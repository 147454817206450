import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const YandexMetrikaHit = () => {
    let location = useLocation();

    useEffect(() => {
        if (typeof window !== 'undefined' && typeof window.ym !== 'undefined') {
            window.ym(process.env.REACT_APP_YANDEX_METRIKA_ID, 'hit');
            console.log('YA hit');
        }
        // console.log('location.pathname');
        // console.log(location.pathname);

        // console.log('location.search');
        // console.log(location.search);

        // console.log('window.location.href');
        // console.log(window.location.href);


    }, [location.pathname]);
}
