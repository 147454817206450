import React, {useEffect, useRef, useState} from 'react';
import classes from "./HiddenReviews.module.scss";
import {dateParse} from "../../../helpers/DateHelper";
import Close from '../../../images/icons/Close.svg';
import {priceFormatting} from "../../../helpers/PriceHelper";
import ExcursionService from "../../../API/ExcursionService";
import PageLoader from "../../UI/PageLoader/PageLoader";
import RatingStarFilled from "../../../images/icons/RatingStarFilled.svg";

const HiddenReviews = (props) => {
    const id = props.excursion.id;
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [reviews, setReviews] = useState([]);
    const [isReviewsLoaded, setIsReviewsLoaded] = useState(false);
    const [fluidContainerClasses, setFluidContainerClasses] = useState([classes.reviews_container_fluid, 'hidden']);
    const lastReview = useRef();
    const observer = useRef();

    async function fetchReviews(id, page) {
        setIsReviewsLoaded(false);
        const response = await ExcursionService.getReviews(id, page);
        const data = response.data;
        setReviews([...reviews, ...data.data]);
        setPage(data.current_page);
        setTotalPages(data.last_page);
        setIsReviewsLoaded(true);
    }

    useEffect(() => {
        fetchReviews(id, page)
    }, [page])

    // Reviews dynamic load
    useEffect(() => {
        if (isReviewsLoaded === false) return;
        if (observer.current) observer.current.disconnect();
        let callback = function (entries, observer) {
            if (entries[0].isIntersecting && page < totalPages) {
                setPage(page + 1);
            }
        }

        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastReview.current);
    }, [isReviewsLoaded])

    // Close reviews
    const reviewsOutsideClick = (event) => {
        if (event.target === event.currentTarget) {
            props.reviewsVisible(false);
        }
    }

    useEffect(() => {
        if (props.isReviewsVisible) {
            setFluidContainerClasses([classes.reviews_container_fluid]);
        } else {
            setFluidContainerClasses([...fluidContainerClasses, 'hidden']);
        }
    }, [props.isReviewsVisible])


    return (
        <div
            className={fluidContainerClasses.join(' ')}
            onClick={reviewsOutsideClick}
        >
            <div className={`roundblock ${classes.reviews_container}`}>
                <div className={classes.close_button_row}>
                    <div
                        className={classes.close_button}
                        onClick={() => props.reviewsVisible(false)}
                    >
                        <img
                            src={Close}
                            alt={'close'}
                        />
                    </div>
                </div>

                <div
                    className={classes.inner_reviews_container}
                >
                    <div className={classes.title_row}>
                        <h2 className={classes.review_title}>
                            Что о нас думают гости:
                        </h2>
                    </div>

                    <div className={classes.reviews_count_row}>
                        <div className={classes.pink_dot}>

                        </div>

                        <div className={classes.reviews_count}>
                            отзывов ({priceFormatting(props.excursion.reviews_count)})
                        </div>
                    </div>

                    <div
                        className={classes.reviews}
                        onMouseEnter={(event) => event.preventDefault()}
                    >
                        {
                            reviews.map((review, index) => {
                                return (
                                    <div
                                        className={`${classes.review_block}`}
                                        key={`review_${index}`}
                                    >
                                        <div className={classes.profile_row}>
                                            <div className={classes.user_avatar}>

                                            </div>

                                            <div className={classes.user_data}>
                                                <div className={classes.name_rating_container}>
                                                    <div className={classes.user_name}>
                                                        {review.is_anonymously ? 'Скрытный авантюрист' : review.user_name}
                                                    </div>

                                                    <div className={classes.user_rating_container}>
                                                        <div
                                                            className={classes.rating_star_row}
                                                        >
                                                            {
                                                                [...Array(+review.rating)].map((_, index) => {
                                                                    return (
                                                                        <img
                                                                            key={index}
                                                                            className={classes.rating_star_img}
                                                                            src={RatingStarFilled}
                                                                            alt={'star'}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>

                                                        <div
                                                            className={classes.rating_star_row_md}
                                                        >
                                                            <img
                                                                className={classes.rating_star_img}
                                                                src={RatingStarFilled}
                                                                alt={'star'}
                                                            />
                                                        </div>


                                                        <div className={classes.user_rating}>
                                                            ({review.rating.toFixed(1)})
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classes.review_date}>
                                                    {dateParse(review.created_at)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.review_body}>
                                            {review.body}
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {!isReviewsLoaded && <PageLoader/>}

                        <div
                            className={classes.observer_block}
                            ref={lastReview}
                        >

                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
};

export default HiddenReviews;