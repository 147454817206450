import React from 'react';
import Close from '../../../images/icons/Close.svg';
import classes from './HbCloseButton.module.scss';

const HbCloseButton = ({className = [], onClick = () => {}}) => {
    return (
        <div
            className={[className, classes.container].join(" ")}
            onClick={onClick}
        >
            <img
                src={Close}
                width={10}
                alt="x"
            />
        </div>
    );
};

export default HbCloseButton;