import React, {useEffect, useState} from 'react';
import classes from "./HbButton.module.scss";
import KeyHole from '../../../images/icons/KeyHole.svg';


// const HbButton = ({children, ...props}) => {
const HbButton = ({children = '', className = '', isLoading = false, onClick = () => {}, color = 'green', type = 'button', notificationCount = 0}) => {
    const [buttonClasses, setButtonClasses] = useState([]);

    useEffect(() => {
        let tempClasses = [classes.HbButton, className];
        if (isLoading) {
            tempClasses.push(classes.disable_button);
        }else {
            switch (color) {
                case 'pink':
                    tempClasses.push(classes.pink_button);
                    break;
                case 'green':
                    tempClasses.push(classes.green_button);
                    break;
                case 'white':
                    tempClasses.push(classes.white_button);
                    break;
                case 'black':
                    tempClasses.push(classes.black_button);
                    break;
                case 'black_white':
                    tempClasses.push(classes.black_white_button);
                    break;
                case 'blue':
                    tempClasses.push(classes.blue_button);
                    break;
                case 'trans_blue':
                    tempClasses.push(classes.transparent_blue_button);
                    break;

                default:
                    tempClasses.push(classes.green_button);
                    break;
            }
        }

        setButtonClasses(tempClasses);
    }, [isLoading])

    return (
        <button
            type={type ? type : 'button'}
            className={buttonClasses.join(' ')}
            onClick={onClick}
            disabled={isLoading}
        >
            {
                isLoading
                ?
                    // <div className={classes.loading_children_block}>
                    //     <div className={classes.loading_children}>

                    //     </div>
                    // </div>
                    <div className={classes.key_hole}>
                        <img
                            className={classes.key_hole_img}
                            src={KeyHole}
                            alt={'loading'}
                        />
                    </div>
                : children
            }

            {
                notificationCount > 0 && !isLoading &&
                <div className={classes.notification_count}>
                    {notificationCount}
                </div>
            }
        </button>
    );
};

export default HbButton;