import React, { useRef, useState } from 'react'
import classes from './CompanyPositioning2.module.scss';

export const CompanyPositioning2 = () => {
    return (
        <div
            className={classes.company_positioning_container}
        >
            <div className={['roundblock_angle_lb', classes.company_positioning_block].join(" ")}>
                <p className={classes.garantee_text}>
                    Не понравится - вернём деньги. <b>Без чека</b>.
                </p>

                {/* <p className={classes.garantee_text}>
                    Впечатляйтесь сейчас, <b>платите потом</b>! <span className={classes.yandex_text}>Яндекс Сплит</span> */}

                    {/* Открывайте новые эмоции, <b>платите потом</b>! <span className={classes.yandex_text}>Яндекс Сплит</span>
                     */}
                    {/* Наслаждайтесь сейчас, <b>платите потом</b>! <span className={classes.yandex_text}>Яндекс Сплит</span> */}
                    {/* Впечатляйся сейчас, <b>плати потом</b>! <span className={classes.yandex_text}>Яндекс Сплит</span> */}
                    {/* Нам <b>доверяют</b>! Используйте <span className={classes.yandex_text}>Яндекс Сплит</span> */}
                {/* </p> */}

                <p className={classes.garantee_text}>
                    {/* <b>Яркие</b> впечатления! */}
                    Гарантируем <b>яркие</b> впечатления!
                </p>

                <p className={classes.garantee_text}>
                    Пишите, звоните - сразу <b>ответим</b>!
                </p>
            </div>
        </div>
    )
}
