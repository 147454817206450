import React, {useEffect, useState} from 'react';
import classes from "./ExcursionDescription.module.scss";
import HbButton from "../../UI/HbButton/HbButton";
import {isFullObj} from "../../../helpers/ObjHelper";
import StartWaypoint from '../../../images/icons/StartWaypoint.svg';
import EndWaypoint from '../../../images/icons/EndWaypoint.svg';

const ExcursionDescription = (props) => {
    const [compositionClasses, setCompositionClasses] = useState([classes.composition]);
    const [isCompositionShow, setIsCompositionShow] = useState(false);
    const [compositionButtonBody, setCompositionButtonBody] = useState('Показать всё');

    useEffect(() => {
        if (isCompositionShow) {
            // setCompositionClasses([...compositionClasses, classes.composition_full]);
            setCompositionClasses([classes.composition_full]);
            setCompositionButtonBody('Скрыть');
        }else {
            setCompositionClasses([classes.composition])
            setCompositionButtonBody('Показать всё');
        }
    }, [isCompositionShow])

    return (
        <div className={classes.description_container}>
            {/* Excursion's meta */}
            <div className={classes.meta_description}>
                {props.excursion.preview_description}
            </div>

            {/* Excursion's includes */}
            {/*<div className={classes.includes_container}>*/}
            {/*    <div className={classes.includes_title}>*/}
            {/*        Что включено*/}
            {/*    </div>*/}

            {/*    <div className={classes.includes_block}>*/}
            {/*        <div className={classes.includes_row}>*/}
            {/*            <div className={classes.includes_img_block}>*/}
            {/*                <img*/}
            {/*                    className={classes.includes_img}*/}
            {/*                    src={ReturnMoney}*/}
            {/*                    alt={'icon'}*/}
            {/*                />*/}
            {/*            </div>*/}

            {/*            <div className={classes.includes_text}>*/}
            {/*                Если вам не понравится экскурсия - мы вернем вам деньги. Без чека.*/}
            {/*            </div>*/}

            {/*        </div>*/}

            {/*        <div className={classes.includes_row}>*/}
            {/*            <div className={classes.includes_img_block}>*/}
            {/*                <img*/}
            {/*                    className={classes.includes_img}*/}
            {/*                    src={ReturnGuarantee}*/}
            {/*                    alt={'icon'}*/}
            {/*                />*/}
            {/*            </div>*/}

            {/*            <div className={classes.includes_text}>*/}
            {/*                Гарантированный возврат при отмене за 10 часов до начала впечатления*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        {*/}
            {/*            isFullObj(props.excursionType) && props.excursionType.includeds.length > 0 && props.excursionType.includeds.map((included, index) => {*/}
            {/*                return (*/}
            {/*                    <div*/}
            {/*                        className={classes.includes_row}*/}
            {/*                        key={`included_${index}`}*/}
            {/*                    >*/}
            {/*                        <div className={classes.includes_img_block}>*/}
            {/*                            <img*/}
            {/*                                className={classes.includes_img}*/}
            {/*                                src={baseURL + included.img_path}*/}
            {/*                                alt={'icon'}*/}
            {/*                            />*/}
            {/*                        </div>*/}

            {/*                        <div className={classes.includes_text}>*/}
            {/*                            {included.body}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                )*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* Excursion's composition */}
            <div className={classes.composition_container}>
                <div className={classes.composition_title}>
                    Композиция экскурсии
                </div>

                <div className={classes.composition_block}>
                    {/*<div className={`truncate ${compositionClasses.join(' ')}`}>*/}
                    <div className={`${compositionClasses.join(' ')}`}>
                        {props.excursion.main_description}
                    </div>

                    <HbButton
                        className={classes.composition_button}
                        color={'black'}
                        onClick={() => setIsCompositionShow(!isCompositionShow)}
                    >
                        {compositionButtonBody}
                    </HbButton>
                </div>
            </div>

            {/* Excursion's route */}
            <div className={classes.route_container}>
                <div className={classes.route_title}>
                    {
                        isFullObj(props.excursionType) && props.excursionType.waypoints.length > 1
                        ?
                            'Маршрут'
                        :
                            'Продолжительность'
                    }
                </div>

                <div className={classes.route_block}>
                    <div className={classes.route_duration}>
                        {isFullObj(props.excursionType) && props.excursionType.duration}
                    </div>

                    {
                        isFullObj(props.excursionType) && props.excursionType.waypoints.length > 1
                        &&
                            <div className={classes.route_grey_line}>

                            </div>
                    }



                    {
                        isFullObj(props.excursionType) && props.excursionType.waypoints.length > 1
                        &&
                            <div className={classes.route_detailed_container}>
                                <div className={classes.route_extreme_container_start}>
                                    <div className={classes.route_extreme_icon}>
                                        <img
                                            src={StartWaypoint}
                                            alt={'start'}
                                        />
                                    </div>

                                    <div className={classes.route_point_waypoint}>
                                        {
                                            isFullObj(props.excursionType) && props.excursionType.waypoints[0].waypoint
                                            // isFullObj(props.excursionType) && props.excursionType.waypoints
                                            // ? props.excursionType.waypoints[0].waypoint
                                            // : ''
                                        }
                                    </div>
                                </div>

                                <div className={classes.route_middle_container}>
                                    <div className={classes.route_pink_vertical}>

                                    </div>

                                    <div className={classes.route_point_container}>
                                        {
                                            isFullObj(props.excursionType) && props.excursionType.waypoints.map((waypoint, index) => {
                                                if (index !== 0 && index !== props.excursionType.waypoints.length - 1) {
                                                    return (
                                                        <div
                                                            className={classes.route_point_row}
                                                            key={`waypoint_${index}`}
                                                        >
                                                            <div className={classes.route_point_dot}>

                                                            </div>

                                                            <div className={classes.route_point_waypoint}>
                                                                {waypoint.waypoint}
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })
                                        }
                                    </div>
                                </div>

                                <div className={classes.route_extreme_container_end}>
                                    <div className={classes.route_extreme_icon}>
                                        <img
                                            src={EndWaypoint}
                                            alt={'end'}
                                        />
                                    </div>

                                    <div className={classes.route_point_waypoint}>
                                        {
                                            isFullObj(props.excursionType) && props.excursionType.waypoints[props.excursionType.waypoints.length - 1].waypoint
                                        }
                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </div>

            {/* Important info */}
            <div className={classes.important_info_container}>
                {
                    isFullObj(props.excursionType) && props.excursionType.importants.length > 0
                    &&
                    <div>
                        <div className={classes.important_info_title}>
                            Важная информация
                        </div>

                        <div className={classes.important_info_block}>
                            {
                                props.excursionType.importants.map((important, index) => {
                                    return (
                                        <div
                                            className={classes.important_info_row}
                                            key={`important_${index}`}
                                        >
                                            <div className={classes.important_info_dot}>

                                            </div>

                                            <div className={classes.important_info}>
                                                {important.body}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ExcursionDescription;