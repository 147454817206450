import React, { useEffect } from 'react';
import classes from "./ExcursionImgCarousel.module.scss";
import { baseURL } from "../../../API/HttpAxios";
import ArrowLeft from "../../../images/icons/ArrowLeft.svg";
import ArrowRight from "../../../images/icons/ArrowRight.svg";
import Close from '../../../images/icons/Close.svg';

const ExcursionImgCarousel = ({ ...props }) => {
    // Add src to img
    const setImgSrc = (id) => {
        const imgs = document.getElementById(id).children;

        for (let i = 0; i < imgs.length; i++) {
            let imgElement = imgs[i].firstChild;
            if (imgElement.getAttribute('src') === '') {
                imgElement.setAttribute('src', imgElement.getAttribute('data-src'));
            }
        }
    }
    // Show img after loading
    const handleLoadImg = (event) => {
        event.currentTarget.parentElement.classList.replace(classes.img_block_preload, classes.img_block_loaded);
        event.currentTarget.classList.remove('hidden');

        const nextParent = event.currentTarget.parentElement.nextElementSibling;
        if (nextParent !== null) {
            const nextImg = nextParent.firstChild;
            nextImg.setAttribute('src', nextImg.getAttribute('data-src'));
        }
        // setImgSrc('img_carousel_row');
    }

    // Func for scrolling by arrows
    const scrollByArrows = (event) => {
        const scrollLeft = document.getElementById(`img_carousel_row`).scrollLeft;
        const imgs = document.getElementsByClassName(classes.img_block_loaded);

        for (const img of imgs) {
            if (scrollLeft >= img.offsetLeft - 10 && scrollLeft <= img.offsetLeft + img.clientWidth) {
                if (event.currentTarget.id === `right_arrow_block` && img.nextElementSibling !== null) {
                    document.getElementById(`img_carousel_row`).scrollLeft = img.nextElementSibling.offsetLeft;
                }

                if (event.currentTarget.id === `left_arrow_block` && img.previousElementSibling !== null) {
                    document.getElementById(`img_carousel_row`).scrollLeft = img.previousElementSibling.offsetLeft;
                }
            }
        }

        hiddenArrows()
    }

    // Func for hidden arrows
    const hiddenArrows = () => {
        const scrollLeft = document.getElementById(`img_carousel_row`).scrollLeft;
        const imgContainerWidth = document.getElementById(`img_carousel_row`).clientWidth;
        const imgs = document.getElementsByClassName(classes.img);
        let imgRowWidth = 0;
        // const imgRowWidth = imgs.item(imgs.length - 1).offsetLeft + imgs.item(imgs.length - 1).clientWidth;
        const imgBlocks = document.querySelectorAll("[data-name='img_block']");
        for (const imgBlock of imgBlocks) {
            imgRowWidth += imgBlock.clientWidth;
        }

        if (scrollLeft === 0) {
            document.getElementById(`left_arrow_block`).classList.add('hidden');
        } else {
            document.getElementById(`left_arrow_block`).classList.remove('hidden');
        }

        if (scrollLeft + imgContainerWidth >= imgRowWidth - 10) {
            document.getElementById(`right_arrow_block`).classList.add('hidden');
        } else {
            document.getElementById(`right_arrow_block`).classList.remove('hidden');
        }
    }

    //Func for visibility arrows by hover and scroll
    const arrowsVisible = () => {
        document.getElementById('carousel_arrow_row').classList.remove('hidden');
        hiddenArrows()
    }

    // Handle img click
    const openGalleryOnArrowRow = (event) => {
        if (event.target === document.getElementById('carousel_arrow_row')) {
            document.getElementById(`img_gallery_container`).classList.remove(`hidden`);
            props.setIsGalleryOpen(true);
            setImgSrc('img_gallery_column');
        }
    }

    const openGallery = (event) => {
        document.getElementById(`img_gallery_container`).classList.remove(`hidden`);
        props.setIsGalleryOpen(true);
        setImgSrc('img_gallery_column');
    }

    // Handle img click
    const closeGallery = (event) => {
        document.getElementById(`img_gallery_container`).classList.add(`hidden`);
        props.setIsGalleryOpen(false);
    }



    return (
        // <div className={classes.container}>
        <div className={`container`}>
            <div className={classes.img_carousel_block}>
                <div
                    id={`carousel_arrow_row`}
                    className={classes.carousel_arrow_row}
                    onMouseOver={arrowsVisible}
                    // onClick={(event) => event.target === document.getElementById('carousel_arrow_row') && document.getElementById(`img_gallery_container`).classList.remove(`hidden`)}
                    onClick={openGalleryOnArrowRow}
                >
                    <div
                        id={`left_arrow_block`}
                        className={[classes.carousel_arrow_block, classes.carousel_arrow_left_block, 'hidden'].join(' ')}
                        onClick={scrollByArrows}
                    >
                        <img
                            src={ArrowLeft}
                            height={15}
                            alt={'left'}
                        />
                    </div>

                    <div
                        id={`right_arrow_block`}
                        className={[classes.carousel_arrow_block, classes.carousel_arrow_right_block, 'hidden'].join(' ')}
                        onClick={scrollByArrows}
                    >
                        <img
                            src={ArrowRight}
                            height={15}
                            alt={'right'}
                        />
                    </div>
                </div>

                <div
                    id={`img_carousel_row`}
                    className={classes.img_carousel_row}
                    onClick={openGallery}
                    // onClick={() => document.getElementById(`img_gallery_container`).classList.remove(`hidden`)}
                    onMouseOver={arrowsVisible}
                    onMouseLeave={() => document.getElementById('carousel_arrow_row').classList.add('hidden')}
                // onScroll={setImgSrc}
                >
                    {
                        props.excursion.imgs.map((img, index) => {
                            return (
                                <div
                                    data-name={'img_block'}
                                    className={classes.img_block_preload}
                                    key={`img_block_${img.id}`}
                                >
                                    <img
                                        onLoad={handleLoadImg}
                                        className={`roundblock ${classes.img} hidden`}
                                        // src={baseURL + img.img_path}
                                        src={index < 1 ? baseURL + img.img_path : ''}
                                        data-src={baseURL + img.img_path}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {/* Gallery block */}
            <div
                id={`img_gallery_container`}
                className={[classes.img_gallery_container, 'hidden'].join(' ')}
            >
                <div className={classes.img_gallery_header}>
                    <div className={`container`}>
                        <div
                            className={classes.img_gallery_close_button}
                            // onClick={() => document.getElementById(`img_gallery_container`).classList.add(`hidden`)}
                            onClick={closeGallery}
                        >
                            <img
                                className={classes.img_gallery_close}
                                src={Close}
                                alt={`close`}
                            />
                        </div>
                    </div>
                </div>

                <div
                    id={`img_gallery_column`}
                    className={`container ${classes.img_gallery_column}`}
                >
                    {
                        props.excursion.imgs.map((img) => {
                            return (
                                <div
                                    className={classes.img_gallery_block}
                                    key={`img_gallery_${img.id}`}
                                >
                                    <img
                                        // onLoad={ImgStretchingFixedDiv}
                                        className={`${classes.img_gallery}`}
                                        src={''}
                                        alt={''}
                                        data-src={baseURL + img.img_path}
                                    />
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        </div>
    );
};

export default ExcursionImgCarousel;