import React, { useContext, useEffect, useMemo, useState } from 'react';
import classes from "./ExcursionBookingResultPage.module.scss";
import { useFetching } from "../../hooks/useFetching";
import ExcursionService from "../../API/ExcursionService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import ExcursionBookingCard
    from "../../components/ExcursionBookingPageComponents/ExcursionBookingCard/ExcursionBookingCard";
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import { dateParse } from "../../helpers/DateHelper";
import { timeParse } from "../../helpers/TimeHelper";
import HbButton from "../../components/UI/HbButton/HbButton";
import { PaymentMethods } from "../../lang/ru/PaymentMethods";
import { priceFormatting } from "../../helpers/PriceHelper";
import { useReceipting } from "../../hooks/useReceipting";
import { useYandexSplit } from 'hooks/useYandexSplit';
import "./ExcursionBookingResultPage.css";

const ExcursionBookingResultPage = () => {
    const { isAuth, setIsAuth, isAuthVisible, setIsAuthVisible, user, setUser } = useContext(AuthContext);
    const [excursion, setExcursion] = useState();
    const [excursionType, setExcursionType] = useState();
    const [booking, setBooking] = useState();
    const [members, setMembers] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const mainParams = useParams();
    const navigate = useNavigate();
    const [authShowTime, setAuthShowTime] = useState(1);
    const [afterBookingImportant, setAfterBookingImportant] = useState('');

    // Get booking result
    const [bookingFetching, isBookingFetchingLoading, bookingFetchingError] = useFetching(async () => {
        const response = await ExcursionService.bookingResult(mainParams.id);
        const data = response.data;
        setExcursion(data.excursion);
        setExcursionType(data.excursion_type);
        setBooking(data.booking);
        setMembers(data.booking.members);
        // setMembers(JSON.parse(data.booking.members));
        setPaymentMethod(PaymentMethods.find(el => el.type === data.booking.payment_method).title);
        setAfterBookingImportant(data.excursion_after_booking_important)
        console.log(response)
    })

    useEffect(() => {
        if (isAuth) {
            bookingFetching();
        } else {
            if (isAuthVisible === false && authShowTime === 1) {
                setIsAuthVisible(true);
                setAuthShowTime(authShowTime + 1);
            } else if (isAuthVisible === false && authShowTime > 1) {
                navigate('/');
            }
        }
    }, [isAuth, isAuthVisible])

    const getMemberList = () => {
        let content = [];
        members.map((member, index) => {
            // let memberType = MemberTypes.find((el) => el.type === member.member_type);
            if (member.count === 0) {
                return;
            }

            content.push(
                <div
                    className={classes.member_row}
                    key={`member_${index}`}
                >
                    <div className={classes.member_type}>
                        {/*{memberType.name}*/}
                        {member.name}
                    </div>

                    <div>
                        x
                    </div>

                    <div className={classes.member_count}>
                        {member.count}
                    </div>
                </div>
            )
        })

        return content;
    }

    const memberList = useMemo(getMemberList, [members]);

    useEffect(() => {
        console.log(bookingFetchingError)
    }, [bookingFetchingError])

    useEffect(() => {
        console.log(excursion)
        console.log(excursionType)
        console.log(booking)
        console.log(members)
        console.log(paymentMethod)
    }, [excursion, excursionType, booking, members, paymentMethod])

    // Click to pay
    const [pay, isPayLoading, payError] = useReceipting(async () => {
        const response = await ExcursionService.createPayment(mainParams.id);

        let form = document.getElementById('payment_form');
        const input_order_id = document.createElement('input');
        input_order_id.setAttribute('type', 'hidden');
        input_order_id.setAttribute('name', 'order_id');
        input_order_id.setAttribute('value', response.data.payment_id);
        form.prepend(input_order_id);

        form.submit();
    });

    // Success url for back to site
    const location = window.location;
    const success_url = location.protocol + '//' + location.hostname + ':' + location.port + location.pathname;
    // const success_url = location.protocol + '//' + location.hostname + location.pathname;

    // Invoice data for form
    const [invoiceData, setInvoiceData] = useState('');

    useEffect(() => {
        if (!isBookingFetchingLoading) {
            let invoiceDataItems = [];
            // For invoice with full description
            // for (const member of members) {
            //     let memberType = MemberTypes.find((el) => el.type === member.member_type);
            //     invoiceDataItems.push({
            //         name: `${excursion.title} ${memberType.name} x ${member.count}`,
            //         unit: "piece",
            //         price: member.discount_price,
            //         quantity: member.count,
            //         sum: member.discount_price * member.count,
            //         vat_mode: "none"
            //     });
            // }

            invoiceDataItems.push({
                name: excursion.title,
                unit: "piece",
                price: booking.prepayment_amount,
                quantity: 1,
                sum: booking.prepayment_amount,
                vat_mode: "none"
            })

            setInvoiceData(JSON.stringify({
                items: invoiceDataItems
            }))
        }

    }, [excursion, booking])

    // Yandex split
    // const [YaPay, paymentData, setPaymentData] = useYandexSplit({timeOut: 0});

    // useEffect(() => {
    //     if (YaPay !== null && booking !== undefined) {
    //         setPaymentData({
    //             ...paymentData,
    //             totalAmount: booking.prepayment_amount
    //         });
    //     }
    // }, [YaPay, booking])

    // // Обработчик на клик по кнопке
    // // Функция должна возвращать промис которые резолвит ссылку на оплату полученную от бэкенда Яндекс Пэй
    // // Подробнее про создание заказа: https://pay.yandex.ru/ru/docs/custom/backend/yandex-pay-api/order/merchant_v1_orders-post
    // async function onPayButtonClick() {
    //     // Создание заказа...
    //     // и возврат URL на оплату вида 'https://pay.ya.ru/l/XXXXXX'
    //     const response = await ExcursionService.findOrCreateYandexPayOrder(booking.id, success_url);

    //     console.log(response);

    //     return response.data.data.paymentUrl;
    // }

    // // Обработчик на ошибки при открытии формы оплаты
    // function onFormOpenError(reason) {
    //     // Выводим информацию о недоступности оплаты в данный момент
    //     // и предлагаем пользователю другой способ оплаты.
    //     console.error(`Payment error — ${reason}`);
    // }

    // const [activePaymentSession, setActivePaymentSession] = useState(null);

    // useEffect(() => {
    //     if (activePaymentSession !== null) {
    //         activePaymentSession.destroy();
    //     }

    //     if (paymentData !== null && paymentData.totalAmount !== null) {
    //         console.log('Creating session...');

    //         YaPay.createSession(paymentData, {
    //             onPayButtonClick: onPayButtonClick,
    //             onFormOpenError: onFormOpenError,
    //         })
    //             .then((paymentSession) => {
    //                 setActivePaymentSession(paymentSession);

    //                 paymentSession.mountWidget(
    //                     document.querySelector('#yandex_split_widget'),
    //                     { widgetType: YaPay.WidgetType.BnplRequired }
    //                 )
    //             })
    //     }
    // }, [paymentData])



    return (
        <div className={'container_fluid'}>
            {
                isAuth === true
                    ?
                    // &&
                    isBookingFetchingLoading
                        ?
                        <PageLoader />
                        :
                        bookingFetchingError === ''
                            ?
                            <div className={`container ${classes.container}`}>
                                <div className={classes.inner_container}>
                                    <div className={classes.title_container}>
                                        Поздравляем!<br />Вы забронировали впечатление!
                                    </div>

                                    <div className={classes.description_container}>
                                        На ваш email отправлено сообщение с подробной информацией <br />
                                        Гид свяжется с вами за несколько часов до начала
                                    </div>

                                    <div className={classes.members}>
                                        {memberList}
                                    </div>

                                    <div className={classes.date}>
                                        <b>Дата:</b>
                                        <div className={classes.date_info}>
                                            {dateParse(booking.date)}
                                        </div>
                                    </div>

                                    <div className={classes.time}>
                                        <b>Время:</b>
                                        <div className={classes.time_info}>
                                            {timeParse(booking.time)}
                                        </div>
                                    </div>

                                    <div className={classes.important}>
                                        <b>Важная информация для гостя:</b>
                                        <div className={classes.important_info}>
                                            {afterBookingImportant}
                                        </div>
                                    </div>

                                    <div className={classes.payment_method}>
                                        <b>Способ оплаты:</b>
                                        <div className={classes.payment_info}>
                                            {paymentMethod}
                                        </div>
                                    </div>

                                    {/* Full or part payment on site */}
                                    {
                                        (booking.payment_method !== 'postpaid' && booking.payment_status === 'pending')
                                            ?
                                            <div className={classes.payment_form_block}>
                                                <form
                                                    method="POST"
                                                    className="application"
                                                    acceptCharset="UTF-8"
                                                    action="https://partner.life-pay.ru/alba/input/"
                                                    id={'payment_form'}
                                                >
                                                    <input
                                                        type="hidden"
                                                        name="key"
                                                        // value="Im9jHfIKwvEXBHzb3cp/ADXbmqUxHSPaxBZnHc1HM1g="
                                                        // value="5szBehcrn4pCGyopHhfxZk3hAAFNqac07+6BHLtXZKw="
                                                        value="5dnO22nOLU2aRSUSlH+qWecO5KxQI6u0RuGdK2PGLBQ="
                                                    // value="N3Ts3OJNJJ14Ru2svWdu27DGsJ2iLNLBxbClPzAN6LI="
                                                    />

                                                    <input
                                                        type='hidden'
                                                        name='invoice_data'
                                                        value={invoiceData}
                                                    />

                                                    <input
                                                        type="hidden"
                                                        name="cost"
                                                        value={booking.prepayment_amount}
                                                    />

                                                    <input
                                                        type="hidden"
                                                        name="name"
                                                        value={excursion.title}
                                                    />

                                                    <input
                                                        type="hidden"
                                                        name="default_email"
                                                        value={user.email}
                                                    />

                                                    <input
                                                        type="hidden"
                                                        name="url_success"
                                                        value={success_url}
                                                    />

                                                    <HbButton
                                                        id={"a1lite_button"}
                                                        onClick={pay}
                                                        color={'blue'}
                                                        className={classes.payment_button}
                                                        isLoading={isPayLoading}
                                                    >
                                                        Оплатить сразу {priceFormatting(booking.prepayment_amount)}
                                                        {/*<span className={"verdana_regular"}>₽</span>*/}
                                                    </HbButton>
                                                </form>

                                                {/* Yandex split */}
                                                <div className={classes.yandex_split_widget}>
                                                    {/* <div id="yandex_split_widget"></div> */}
                                                </div>

                                                <div className={classes.paid_info}>Если Вы уже оплатили, то подождите пару минут и обновите страницу!
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    {
                                        (booking.payment_method !== 'postpaid' && booking.payment_status === 'paid')
                                            ?
                                            <div className={classes.paid_text}>
                                                Вы оплатили: {booking.prepayment_amount}
                                                {/*<span className={"verdana_regular"}>₽</span>*/}
                                            </div>
                                            :
                                            ''
                                    }

                                    {
                                        (booking.payment_method !== 'full_payment' && booking.payment_status !== 'canceled')
                                            ?
                                            <div className={classes.post_paid}>
                                                Доплатить гиду на месте: {priceFormatting(booking.total_amount - booking.prepayment_amount)}
                                                {/*<span className={"verdana_regular"}>₽</span>*/}
                                            </div>
                                            : ''
                                    }

                                    <Link
                                        to={'/e/' + excursionType.excursion_id}
                                        className={classes.booking_card_container}
                                    >
                                        <ExcursionBookingCard
                                            excursion={excursion}
                                            excursionType={excursionType}
                                        />
                                    </Link>
                                </div>

                            </div>
                            :
                            <div className={`container ${classes.container}`}>
                                <div className={classes.title_container}>
                                    {bookingFetchingError}
                                </div>
                            </div>
                    : ''
                // <UserAuth/>
                // setIsAuthVisible(true)
            }

        </div>
    );
};

export default ExcursionBookingResultPage;